var util = {
  methods: {
    instantiateSimulationHelper () {
      var unix = parseInt((new Date()).getTime() / 1000)

      var herd = ['goats_meat', 'goats_milk', 'sheep_meat', 'cattl_meat', 'cattl_milk']
      var type = ['matrizes', 'reprodutores', 'jovens']

      var s = {
        code: '',
        label: '',
        farm: '',
        state: '',
        city: '',
        precipitation: '',
        size: 0,
        pasture_low: 0,
        pasture_middle: 0,
        pasture_high: 0,
        pasture_buffel_low: 0,
        pasture_buffel: 0,
        pasture_buffel_high: 0,
        pasture_cynodon_low: 0,
        pasture_cynodon_middle: 0,
        pasture_cynodon_high: 0,
        pasture_brachiaria_low: 0,
        pasture_brachiaria_middle: 0,
        pasture_brachiaria_high: 0,
        pasture_andropogon_low: 0,
        pasture_andropogon_middle: 0,
        pasture_andropogon_high: 0,
        pasture_panicum_low: 0,
        pasture_panicum_middle: 0,
        pasture_panicum_high: 0,
        pasture_thinned: 0,
        pasture_buffel: 0,
        source_palma: 0,
        source_proteina: 0,
        source_silagem: 0,
        source_feno: 0,
        source_capineira: 0,
        created: unix,
        updated: unix,
        active: 1
      }

      for (var i = 0; i < herd.length; i++) {
        for (var j = 0; j < type.length; j++) {
          s[herd[i] + '_' + type[j]] = 0
          s[herd[i] + '_' + type[j] + '_weight'] = 0
        }
      }

      return s
    },
    instantiateAuxSourcesHelper () {
      var s = {
        source_palma: 0,
        source_proteina: 0,
        source_silagem: 0,
        source_feno: 0,
        source_capineira: 0
      }
      return s
    },

    validateHelper (simulation) {
      var herd = ['goats_meat', 'goats_milk', 'sheep_meat', 'cattl_meat', 'cattl_milk']
      var category = ['matrizes', 'reprodutores', 'jovens']

      var v = {
        step1: simulation.label.length > 0 && simulation.farm.length > 0 && simulation.size > 0 && simulation.state.length > 0 && simulation.city.length > 0 && simulation.precipitation !== '',
        step2: simulation.pasture_low + simulation.pasture_middle + simulation.pasture_high + simulation.pasture_buffel_low + simulation.pasture_buffel + simulation.pasture_buffel_high + simulation.pasture_cynodon_low + simulation.pasture_cynodon_middle + simulation.pasture_cynodon_high + simulation.pasture_brachiaria_low + simulation.pasture_brachiaria_middle + simulation.pasture_brachiaria_high + simulation.pasture_andropogon_low + simulation.pasture_andropogon_middle + simulation.pasture_andropogon_high + simulation.pasture_panicum_low + simulation.pasture_panicum_middle + simulation.pasture_panicum_high > 0,
        step3: (function (s) {
          for (var i = 0; i < herd.length; i++) {
            for (var j = 0; j < category.length; j++) {
              if (simulation[herd[i] + '_' + category[j]] > 0 && simulation[herd[i] + '_' + category[j] + '_weight'] > 0) {
                return true
              }
            }
          }

          return false
        }(simulation))
      }

      v.all = v.step1 && v.step2 && v.step3

      return v
    }
  }
}

export default util
