<template>
  <v-container class="px-3 py-3">
    <v-row>
      <v-col xs="12" class="px-2">
        <v-card color="brown darken-1" class="white--text" dark>
          <v-card-text class="justify-center" style="text-align: center;">
            <strong>Atenção!</strong> Os dados mostrados foram sincronizados com a nuvem em:
            <v-chip color="orange" text-color="white" class="mt-3">
              <v-avatar class="mr-2">
                <v-icon>schedule</v-icon>
              </v-avatar>
              {{ synchronized | format }}
            </v-chip>
          </v-card-text>
          <v-card-actions class="py-0 px-2" v-if="authenticated">
            <v-btn outlined color="white" block @click="go('Sincronizar')">
              <v-icon left>
                sync
              </v-icon>
              Sincronizar Agora
            </v-btn>
          </v-card-actions>
          <v-card-actions class="py-0" v-if="authenticated">
            <v-switch label="Sincronizar automaticamente" v-model="alwaysSync" @change="changeAlwaysSync" color="white" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="px-1 mt-1">
      <v-col xs="6" class="px-1">
        <v-btn outlined block @click.native="go('Configurações')" style="min-width: 50px;">
          <v-icon>settings</v-icon>
        </v-btn>
      </v-col>
      <!--
      <v-flex xs6 class="px-1">
        <v-btn outlined block @click.native="go('Avaliar')" style="min-width: 50px;">
          <v-icon>star</v-icon>
        </v-btn>
      </v-flex>
      -->
      <v-col xs="6" class="px-1">
        <v-btn outlined block @click.native="go('Sobre')" style="min-width: 50px;">
          <v-icon>info</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <confirm-wrapper ref="confirm" />
  </v-container>
</template>

<script>
import moment from 'moment'
import timestamp from 'unix-timestamp'

import ConfirmWrapper from '../components/Confirm.vue'

export default {
  components: {
    ConfirmWrapper
  },
  props: ['synchronized', 'authenticated'],
  data () {
    return {
      menu: [
        { name: 'Configurações', icon: 'cog' },
        { name: 'Contato', icon: 'envelope' },
        { name: 'Sobre', icon: 'info-circle' }
      ],
      alwaysSync: false,
      rating: 0
    }
  },
  created () {
    this.alwaysSync = this.$localStorage.get('alwaysSync')
  },
  methods: {
    go (choose) {
      this.$emit('clicked', choose)
    },
    changeAlwaysSync (value) {
      var title, message

      if (value) {
        title = 'Ativar Sincr. Automática'
        message = 'Tem certeza que deseja ATIVAR a sincronização automática? Toda vez que a lista de simulações for mostrada, será realizada uma tentativa de sincronização com o servidor.'
      } else {
        title = 'Desativar Sincr. Automática'
        message = 'Tem certeza que deseja DESATIVAR a sincronização automática? A sincronização dos dados com o servidor será feita apenas quando o aplicativo for iniciado ou se você fizer explicitamente.'
      }

      var self = this

      this.$refs.confirm.open(
        title,
        message, { color: 'brown darken-1', persistent: true }).then((confirm) => {
        if (confirm) {
          self.$localStorage.set('alwaysSync', value)
        } else {
          self.alwaysSync = !value
        }
      })
    }
  },
  filters: {
    format: function (value) {
      if (!value) return 'Nunca sincronizou!'
      return moment(timestamp.toDate(value)).format('D/M/YY HH:mm')
    }
  }
}
</script>
