import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'

import Home from '../pages/Home'
import About from '../pages/About'
import Login from '../pages/Login'
import Report from '../pages/Report'
import Settings from '../pages/Settings'
import Simulation from '../pages/Simulation'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', name: 'Login', component: Login },
    { path: '/home', name: 'Simulações', component: Home },
    { path: '/about', name: 'Sobre', component: About },
    { path: '/report/:code', name: 'Relatório', component: Report },
    { path: '/Settings', name: 'Configurações', component: Settings },
    { path: '/simulation/:code', name: 'Simulação', component: Simulation }
  ],
  mode: 'history',
  base: process.env.BASE_URL
})

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ANALYTICS,
  checkDuplicatedScript: true,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  },
  router
})

export default router
