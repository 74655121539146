<template>
  <v-app>
    <v-navigation-drawer dark clipped v-model="sidebar" app class="amber darken-2">
      <p class="text-center hidden-lg-and-up mt-3">
        <img src="@/assets/img/logo.png" style="width: 80%;" alt="Logo do Orçamento Forrageiro">
      </p>

      <user-wrapper class="hidden-md-and-up" v-if="user.authenticated" />

      <menu-wrapper @clicked="menu" :synchronized="synchronized" :authenticated="user.authenticated" />

      <p class="text-center hidden-md-and-up mt-3">
        <img src="@/assets/img/partners-white.png" style="width: 60%;" alt="Logo da Embrapa e CNA">
      </p>

      <p class="text-center hidden-sm-and-down" style="position: absolute; bottom: 0;">
        <img src="@/assets/img/partners-white.png" style="width: 60%;" alt="Logo da Embrapa e CNA">
      </p>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark color="primary">
      <v-app-bar-nav-icon @click.stop="sidebar = !sidebar" />
      <v-toolbar-title v-text="!simulations || simulations.length === 0 ? manifest.name : $route.name" />
      <v-spacer class="hidden-sm-and-down" />

      <v-text-field v-if="simulations && simulations.length > 0" append-icon="search" label="Buscar..." single-line hide-details v-model="search" class="mx-2 hidden-sm-and-down" color="white" />
      <v-btn large color="light-green" class="white--text hidden-sm-and-down" v-if="simulations && simulations.length > 0" @click.stop="dialogs.create = !dialogs.create">
        <v-icon left dark>
          add
        </v-icon>
        Nova Simulação
      </v-btn>

      <div class="d-flex align-center" style="margin-left: auto">
        <user-wrapper class="hidden-sm-and-down" v-if="user.authenticated" />
      </div>

      <beta />
    </v-app-bar>

    <v-main>
      <div>
        <v-container fluid grid-list-lg>
          <v-row no-gutters v-if="!simulations || simulations.length === 0">
            <v-col cols="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3">
              <v-card class="px-3 py-2">
                <v-card-title primary-title>
                  <div class="headline">
                    Bem vind@!
                  </div>
                </v-card-title>
                <v-card-text>
                  <p>
                    Este aplicativo é um simulador do <strong>orçamento forrageiro</strong> para propriedades no semiárido brasileiro.
                  </p>
                  <p>
                    A base dos sistemas pecuários no semiárido é o pasto nativo da caatinga. Esse tipo de pasto é caracterizado pela presença de plantas forrageiras nos estratos herbáceo, arbustivo e arbóreo. A quantificação da biomassa de forragem disponível é uma tarefa muito difícil para os produtores. A Embrapa ao longo de seus 40 anos desenvolveu muitos projetos e quantificou a produção de biomassa da caatinga, qualificando essa produção em quatro tipos: baixa oferta de forragem, média oferta de forragem, alta oferta de forragem e pasto nativo com capim-búffel. Essa base de dados foi utilizada no aplicativo Orçamento Forrageiro para quantificar as biomassas de forragens do pasto nativo.
                  </p>
                  <p>
                    O passo seguinte da orçamentação forrageira é quantificar os rebanhos presentes na propriedade, sendo possível para o usuário cadastrar rebanhos de caprinos, ovinos e bovinos, de modo que o aplicativo representa a condição de rebanho das propriedades rurais do semiárido.
                  </p>
                  <p>
                    Por fim, há a possibilidade de o usuário informar se dispõe de fontes suplementares de forragem para alimentar o rebanho. As opções disponíveis no aplicativo são as mais comuns utilizadas na região semiárida: silagem, feno, palma forrageira e capineira.
                  </p>
                  <p>
                    O aplicativo simula então o balanço entre a oferta e a necessidade de forragem, fornecendo um relatório que informa quanto da demanda está sendo atendida pelo pasto nativo. Em caso de balanço de forragem positiva o usuário pode optar por alterar a quantidade de animais no rebanho. Em caso de balanço de forragem negativo o usuário poderá optar por alterar o tamanho do rebanho ou alterar as fontes suplementares de forragem.
                  </p>
                  <p>
                    O aplicativo propicia o uso do pasto nativo sem degradá-lo e fornece informações ao usuário que facilitam o processo de tomada de decisão referente à gestão do recurso forrageiro na propriedade, minimizando riscos e maximizando lucros.
                  </p>
                  <p>
                    <strong>Atenção!</strong> Você ainda não possui nenhuma simulação criada. Para começar a utilizar o aplicativo, <strong>clique no botão abaixo para fazer sua primeira simulação!</strong>
                  </p>
                </v-card-text>
                <v-card-actions class="hidden-sm-and-down">
                  <v-spacer />
                  <v-btn large color="pink" dark to="/" v-if="!user || !user.authenticated">
                    <v-icon left>
                      face
                    </v-icon>
                    Login
                  </v-btn>
                  <v-btn large color="light-green" dark @click.stop="dialogs.create = !dialogs.create">
                    <v-icon left>
                      add
                    </v-icon>
                    Nova Simulação
                  </v-btn>
                </v-card-actions>

                <v-card-actions class="hidden-md-and-up">
                  <v-row no-gutters class="mx-1 my-2">
                    <v-col cols="12" sm="12">
                      <v-btn large color="pink" dark to="/" block v-if="!user || !user.authenticated">
                        <v-icon left>
                          face
                        </v-icon>
                        Login
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-btn large color="light-green" dark block @click.stop="dialogs.create = !dialogs.create">
                        <v-icon left>
                          add
                        </v-icon>
                        Nova Simulação
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <p class="text-center my-4 hidden-md-and-up">
                <img src="@/assets/img/partners.png" style="width: 300px;" alt="Logo da Embrapa e CNA">
              </p>

              <p class="text-center my-4 hidden-sm-and-down">
                <img src="@/assets/img/partners.png" style="width: 600px;" alt="Logo da Embrapa e CNA">
              </p>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="12" class="hidden-md-and-up mt-0 py-0 mb-4" v-if="simulations && simulations.length > 3">
              <v-text-field append-icon="search" label="Buscar..." single-line hide-details v-model="search" />
            </v-col>

            <v-col cols="12" sm="12" class="hidden-md-and-up mt-0 pt-0 mb-4" v-if="simulations && simulations.length > 0">
              <v-btn large block color="light-green" class="white--text" @click.stop="dialogs.create = !dialogs.create">
                <v-icon left dark>
                  add
                </v-icon>
                Nova Simulação
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="4" v-for="s in filteredSimulations" :key="s['code']">
              {{ validated = validateHelper(s) | hack }}
              <v-card class="mb-4 mx-1">
                <v-card-title primary-title>
                  <div class="headline">
                    {{ s.label }}
                  </div>
                  <v-spacer />
                  <v-icon v-html="validated.all ? (s.updated > synchronized ? 'cloud_upload' : 'cloud_done') : 'edit'" color="grey lighten-1" />
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="copy(s)">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          Duplicar
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="remove(s)">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          Apagar
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-text>
                  Propriedade
                  <strong>{{ s.farm || 'N/A' }}</strong>, em
                  <strong>{{ s.city || 'N/A' }} - {{ s.state || 'N/A' }}</strong>, com
                  <strong>{{ s.size | formatNumber }} ha</strong>. Editada em
                  <strong>{{ s.updated | formatDate }}</strong>.
                </v-card-text>
                <v-card-text v-if="validated.all" class="px-2 py-0">
                  {{ balance = balanceHelper(s) | hack }}
                  {{ source = sourceHelper(s) | hack }}
                  {{ stats = finalStatsHelper(balance, source) | hack }}
                  <v-alert :value="true" outlined :color="stats.final < 0 ? 'error' : 'success'" :icon="stats.final > 0 ? 'thumb_up' : 'thumb_down'">
                    Forragem&nbsp;<strong>{{ stats.final &lt; 0 ? 'deficitária' : 'excedente' }}</strong>&nbsp;em&nbsp;<strong>{{ stats.lower &lt; 0 ? stats.final : stats.lower | formatNumber }} {{ stats.lower &lt; 0 ? 'Kg/ano' : 'Kg/mês' }}</strong>!
                  </v-alert>
                </v-card-text>
                <v-card-actions class="px-2">
                  <v-row wrap class="pb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-col cols="12" class="py-1">
                      <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" @click="report(s)" block large depressed :disabled="!validated.all">
                        <v-icon dark class="mr-2">
                          mdi-file-chart
                        </v-icon> Relatório
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-btn color="primary" class="white--text ma-0" style="min-width: 60px;" @click="edit(s)" block large depressed>
                        <v-icon dark class="mr-2">
                          mdi-pencil
                        </v-icon> Editar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap class="pb-3" v-else>
                    <v-col cols="6" class="py-1 pr-1">
                      <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" @click="report(s)" block large depressed :disabled="!validated.all">
                        <v-icon dark class="mr-2">
                          mdi-file-chart
                        </v-icon> Relatório
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="py-1 pl-1">
                      <v-btn color="primary" class="white--text ma-0" style="min-width: 60px;" @click="edit(s)" block large depressed>
                        <v-icon dark class="mr-2">
                          mdi-pencil
                        </v-icon> Editar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" class="px-2" v-if="simulations && simulations.length > 0 && filteredSimulations.length === 0">
              <v-alert :value="true" type="warning">
                Sua busca com o termo "{{ search }}" não retornou resultados!
              </v-alert>
            </v-col>
          </v-row>

          <v-dialog v-model="dialogs.create" min-width="250px" max-width="500px">
            <v-card>
              <v-card-title class="grey lighten-4 py-4 title">
                Criar Simulação
              </v-card-title>
              <v-container>
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field v-model="name" label="Nome da Simulação" :counter="15" :rules="rules.name" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-spacer />
                <v-btn color="error" @click="dialogs.create = false">
                  Cancelar
                </v-btn>
                <v-btn color="success" @click="create">
                  Criar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <confirm-wrapper ref="confirm" />

          <message-wrapper ref="message" />

          <v-dialog v-model="wait" persistent max-width="300px">
            <v-card>
              <v-card-text style="text-align: center;">
                <v-progress-circular :size="100" :width="15" :rotate="360" :value="progress" color="teal">
                  {{ progress }}%
                </v-progress-circular>
              </v-card-text>
              <v-card-title class="headline" block style="text-align: center;">
                Sincronizando... por favor, aguarde!
              </v-card-title>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import manifest from '../../public/manifest.json'
import brazil from '@/assets/brazil.json'
import { v1 as uuid } from 'uuid'
import moment from 'moment'
import axios from 'axios'
import timestamp from 'unix-timestamp'
import crypto from 'crypto-js'
import query from 'querystring'

import UserWrapper from '@/components/User'
import ConfirmWrapper from '@/components/Confirm'
import MessageWrapper from '@/components/Message'
import MenuWrapper from '@/components/Menu'
import Beta from '@/components/Beta'

import UtilHelper from '@/helpers/util'
import SimulatorHelper from '@/helpers/simulator'

export default {
  components: {
    UserWrapper, ConfirmWrapper, MessageWrapper, MenuWrapper, Beta
  },
  mixins: [
    UtilHelper, SimulatorHelper
  ],
  data () {
    return {
      manifest: manifest,
      sidebar: false,
      wait: false,
      progress: 0,
      dialogs: {
        create: false,
        delete: false
      },
      valid: false,
      user: null,
      search: '',
      simulations: null,
      name: '',
      rules: {
        name: [
          v => (v && v.trim().length > 0) || 'Insira um nome para sua simulação!',
          v => (v && v.length < 16) || 'O nome não pode ter mais de 15 caracteres!'
        ]
      },
      drawer: false,
      group: null,
      synchronized: null
    }
  },

  watch: {
    group () {
      this.drawer = false
    }
  },
  created () {
    this.user = this.$localStorage.get('user')
  },
  mounted () {
    if (this.user.authenticated) {
      this.synchronized = this.$localStorage.get('synchronized')

      var alwaysSync = this.$localStorage.get('alwaysSync')

      if (this.$root.$data.trySync || alwaysSync) {
        this.sync()
      } else {
        this.refresh()
      }
    } else {
      this.refresh()
    }
  },
  methods: {
    newSimulation () {
      if (this.user != null) {
        this.dialogs.create = true
      }
    },
    refresh () {
      this.dialogs.create = false

      this.name = ''

      this.$db.simulation
        .where('active').equals(1)
        .reverse().sortBy('updated')
        .then(simulations => { this.simulations = simulations })
    },
    sync () {
      this.wait = true

      var self = this

      var err = function (error) {
        console.log(error)

        self.$root.$data.trySync = false

        self.wait = false

        self.refresh()

        self.$refs.message.open('Atenção! Erro ao tentar sincronizar. Por favor, tente novamente mais tarde.', 'error')

        self.$err(error)
      }

      var now = Math.floor(timestamp.now())

      var lastSync = this.$localStorage.get('synchronized')

      console.log('Now: ' + now)
      console.log('User: ' + JSON.stringify(this.user))

      var headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Application: process.env.VUE_APP_API_NAME,
        Authorization: 'Bearer ' + this.user.token
      }

      console.log(JSON.stringify(headers))

      const api = process.env.VUE_APP_API_URL

      self.progress = 5

      console.log('#1 - Trying to connect in remote server...')

      axios.get(api + '/status', { timeout: 2000, headers: headers }).then(function (response) {
        console.log('#1 - Success!')

        self.progress = 15

        console.log('#2 - Getting from local DB all items edited from last sync: ' + timestamp.toDate(lastSync))
        
        self.$db.simulation
          .where('updated').above(lastSync).toArray()
          .then(simulations => {
            console.log('#2 - Success! Getted ' + simulations.length + ' simulations.')

            const promises = []

            simulations.forEach(s => {
              if (self.validateHelper(s).all) {
                promises.push(axios.put(api + '/simulation/' + s.code, query.stringify(s), { headers: headers }))
              }
            })

            console.log('#3 - Sending all items edited from last sync: ' + promises.length)

            axios.all(promises).then(function (results) {
              results.forEach(r => {
                console.log(r.data)
              })

              console.log('#3 - Success!')

              self.progress = 50

              console.log('#4 - Geting all items in server from last sync. Last sync in timestamp: ' + lastSync)

              axios.get(api + '/simulation/mine/' + lastSync, { headers: headers }).then((response) => {
                console.log('#4 - Success!')

                self.progress = 80

                var items = response.data

                items.forEach(i => {
                  console.log('Getted: ' + i.code + '!')
                })

                console.log('#5 - Saving all items getted from server and deleting inactivated items.')

                self.$db.transaction('rw', self.$db.simulation, () => {
                  items.forEach(d => {
                    self.$db.simulation.put(d)
                  })

                  self.$db.simulation.where('active').equals(0).delete()
                }).then(result => {
                  console.log('#6 - All done! Updating control variables: ' + timestamp.toDate(now))

                  self.$localStorage.set('synchronized', now)

                  self.synchronized = now

                  self.$root.$data.trySync = false

                  self.progress = 100

                  self.refresh()

                  setTimeout(function () {
                    self.wait = false

                    self.$refs.message.open('Sincronização realizada com sucesso!', 'success')
                  }, 300)
                }).catch(err)
              }).catch(err)
            }).catch(err)
          }).catch(err)
      }).catch(error => {
        console.log(error)

        self.$root.$data.trySync = false

        self.wait = false

        self.refresh()

        self.$refs.message.open('Atenção! O servidor remoto parece estar indisponível para sincronização dos dados. Por favor, verifique sua conexão com a internet ou tente novamente mais tarde.', 'yellow darken-3', 6000)

        self.$err(error)
      })
    },
    create () {
      if (this.$refs.form.validate()) {
        var s = this.instantiateSimulationHelper()

        s.code = uuid()
        s.label = this.name
        s.created = timestamp.fromDate(new Date())
        s.updated = timestamp.fromDate(new Date())
        s.active = 1

        this.$db.simulation
          .add(s)
          .then(() => this.$router.push({ path: '/simulation/' + s.code }))
      }
    },
    report (simulation) {
      this.$router.push({ path: '/report/' + simulation.code })
    },
    edit (simulation) {
      this.$router.push({ path: '/simulation/' + simulation.code })
    },
    copy (simulation) {
      this.$refs.confirm.open(
        'Copiar Simulação',
        'Tem certeza de que deseja duplicar a simulação "' + simulation.label + '"?', { color: 'deep-purple darken-3' }).then((confirm) => {
        if (confirm) {
          var s = JSON.parse(JSON.stringify(simulation))

          s.code = uuid()
          s.label = s.label.substr(0, 13) + ' 2'
          s.updated = timestamp.fromDate(new Date())
          s.active = 1

          this.$db.simulation
            .add(s)
            .then(() => this.$router.push({ path: '/simulation/' + s.code }))
        }
      })
    },
    remove (simulation) {
      this.$refs.confirm.open(
        'Apagar Simulação',
        'Tem certeza de que deseja apagar a simulação "' + simulation.label + '"? Esta ação é irreversível!',
        { color: 'red' }).then((confirm) => {
        if (confirm) {
          simulation.active = 0
          simulation.updated = timestamp.fromDate(new Date())

          this.$db.simulation.where('code')
            .equals(simulation.code)
            .modify(simulation)
            .then(() => {
              this.refresh()
              this.$refs.message.open('Simulação apagada com sucesso!', 'success')
            })
        }
      })
    },
    uf (state) {
      for (var i = 0; i < brazil.states.length; i++) {
        if (brazil.states[i].name === state) {
          return brazil.states[i].uf
        }
      }

      return null
    },
    menu (choose) {
      console.log('Menu option choosed: ' + choose)

      switch (choose) {
        case 'Sincronizar':
          this.sidebar = false

          this.sync()

          break

        case 'Configurações':
          this.sidebar = false

          this.$router.push({ path: '/settings' })

          break

        case 'Sobre':
          this.sidebar = false

          this.$router.push({ path: '/about' })

          break
      }
    }
  },
  computed: {
    filteredSimulations () {
      if (!this.search || /^\s*$/.test(this.search)) {
        return this.simulations
      } else {
        var self = this

        return this.simulations.filter(function (s) {
          return self._.includes(s.label.toLowerCase(), self.search.toLowerCase())
        })
      }
    }
  },
  filters: {
    formatDate (value) {
      if (!value) return ''

      return moment(timestamp.toDate(value)).format('D/M/YY HH:mm')
    },
    formatNumber (value) {
      return Number(Math.round(value)).toLocaleString('pt-BR')
    },
    hack (value) {
      return ''
    }
  }
}
</script>
