import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import storage from 'vue-localstorage'
import db from './db'
import Airbrake from 'airbrake-js'
import VueWebP from 'v-webp'

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.use(VueWebP)

Vue.config.productionTip = false

Vue.use(storage)

Vue.use(storage, {
  name: 'ls',
  bind: true
})

var errbit = new Airbrake({
  projectId: 1,
  projectKey: process.env.VUE_APP_TRACKING_KEY,
  host: process.env.VUE_APP_TRACKING_HOST,
  environment: process.env.NODE_ENV
})

Vue.prototype.$err = async function (error) {
  var pkg = require('../package.json')

  var params = {
    version: pkg.version
  }

  if (error !== null && typeof error === 'object') {
    if (error.response !== null &&
      typeof error.response !== 'undefined' &&
      error.response.data !== null &&
      typeof error.response.data !== 'undefined') {
      params.info = error.response.data
    }

    if (error.name !== null && typeof error.name !== 'undefined') {
      params.name = error.name
    }
  }

  await errbit.notify({
    error: error,
    params
  })
}

Vue.prototype.$db = db

new Vue({
  render: h => h(App),
  vuetify,
  router,
  components: { App },
  data: {
    trySync: true
  }
}).$mount('#app')
