<template>
  <v-app light>
    <v-app-bar bar clipped-left fixed dark color="primary">
      <v-btn icon @click.native="cancel">
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-toolbar-title v-text="$route.name" />

      <v-spacer />

      <beta />
    </v-app-bar>

    <v-main>
      <v-container fluid grid-list-lg>
        <v-row class="mt-10">
          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3" v-if="!user.authenticated">
            <v-card class="px-3 py-2">
              <v-card-title class="headline">
                Efetuar Login
                <v-spacer />
                <v-icon large>
                  cloud_upload
                </v-icon>
              </v-card-title>
              <v-card-text>
                Opcionalmente você pode efetuar <strong>login</strong> para sincronizar suas simulações com a
                <a href="https://embrapa.br" target="_blank" rel="noopener">Embrapa</a>, de forma a
                <strong>recuperá-las em caso de perda ou troca do dispositivo</strong> (computador, celular, tablet, etc).
              </v-card-text>
              <v-card-actions class="hidden-sm-and-down">
                <v-spacer />
                <v-btn large color="pink" dark to="/">
                  <v-icon left>
                    face
                  </v-icon>
                  Fazer Login
                </v-btn>
              </v-card-actions>
              <v-card-actions class="hidden-md-and-up">
                <v-btn large color="pink" dark block to="/">
                  <v-icon left>
                    face
                  </v-icon>
                  Fazer Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3" v-if="user.authenticated">
            <v-card class="px-3 py-2">
              <v-card-title class="headline">
                Trocar Usuário
                <v-spacer />
                <v-icon large>
                  supervisor_account
                </v-icon>
              </v-card-title>
              <v-card-text>
                Clique no botão abaixo para <strong>remover suas informações de autenticação</strong> e logar com outro usuário:
              </v-card-text>
              <v-card-actions class="hidden-sm-and-down">
                <v-spacer />
                <v-btn large color="error" @click="cleanup">
                  <v-icon left>
                    swap_horiz
                  </v-icon>
                  Trocar Usuário
                </v-btn>
              </v-card-actions>
              <v-card-actions class="hidden-md-and-up">
                <v-btn large color="error" block @click="cleanup">
                  <v-icon left>
                    swap_horiz
                  </v-icon>
                  Trocar Usuário
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
            <v-card class="px-3 py-2">
              <v-card-title class="headline">
                Contato
                <v-spacer />
                <v-icon large>
                  chat
                </v-icon>
              </v-card-title>
              <v-card-text>
                Clique no botão abaixo para <strong>entrar em contato</strong> ou <strong>reportar algum problema técnico</strong>:
              </v-card-text>
              <v-card-actions class="hidden-sm-and-down">
                <v-spacer />
                <v-btn large color="success" href="https://api.whatsapp.com/send?phone=556733682193" target="_blank">
                  <v-icon left>
                    fab fa-whatsapp
                  </v-icon>
                  Enviar Whats
                </v-btn>
                <v-btn large color="info" @click="email">
                  <v-icon left>
                    mail
                  </v-icon>
                  Enviar e-Mail
                </v-btn>
              </v-card-actions>
              <v-card-actions class="hidden-md-and-up">
                <v-btn large color="success" block href="https://api.whatsapp.com/send?phone=556733682193" target="_blank">
                  <v-icon left>
                    fab fa-whatsapp
                  </v-icon>
                  Enviar Whats
                </v-btn>
              </v-card-actions>
              <v-card-actions class="hidden-md-and-up">
                <v-btn large color="info" block @click="email">
                  <v-icon left>
                    mail
                  </v-icon>
                  Enviar e-Mail
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
            <p class="body-2 text-xs-center">
              Versão {{ version }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <confirm-wrapper ref="confirm" />

    <dialog-wrapper ref="dialog" />
  </v-app>
</template>

<script>
import ConfirmWrapper from '../components/Confirm'
import DialogWrapper from '../components/Dialog'
import Beta from '../components/Beta'

import UtilHelper from '../helpers/util'

var pkg = require('../../package.json')

export default {
  components: {
    ConfirmWrapper,
    DialogWrapper,
    Beta
  },
  mixins: [
    UtilHelper
  ],
  data () {
    return {
      version: pkg.version,
      user: null
    }
  },
  beforeCreate () {
    /*
    if (!this.$localStorage.get('user').authenticated) {
      this.$router.push('/')
    }
    */
  },
  created () {
    this.user = this.$localStorage.get('user')
  },
  methods: {
    cancel () {
      this.$router.push({ path: '/home' })
    },
    email () {
      window.open('mailto: ' + process.env.VUE_APP_EMAIL + '?subject=Problemas%20na%20vers%C3%A3o%20' + this.version + '%20do%20Or%C3%A7amento%20Forrageiro', '_blank')
    },
    cleanup () {
      var self = this

      var lastSync = this.$localStorage.get('synchronized')

      self.$db.simulation
        .where('updated').above(lastSync).toArray()
        .then(simulations => {
          var hasNotSync = false

          simulations.forEach(s => {
            if (self.validateHelper(s).all) {
              hasNotSync = true
            }
          })

          if (hasNotSync) {
            self.$refs.dialog.open(
              'Ação Necessária',
              'Existem simulações que foram alteradas no dispositivo. Para remover suas informações deste disposito, primeiro você deve SINCRONIZAR seus dados com a nuvem!',
              'Fechar')
          } else {
            self.$refs.confirm.open(
              'Trocar Usuário',
              'Tem certeza de que deseja LIMPAR TODOS OS DADOS DO APLICATIVO NESTE DISPOSITIVO e TROCAR O USUÁRIO? Você não irá perder nenhuma simulação já sincronizada com a nuvem, podendo visualizá-las quando logar novamente neste ou em outro dispositivo. Simulações em edição (não sincronizadas) serão perdidas de forma irreversível.',
              { color: 'error' }).then((confirm) => {
              if (confirm) {
                self.$db.simulation.clear()

                self.$localStorage.set('user', {
                  authenticated: false,
                  id: 0,
                  name: '',
                  email: '',
                  picture: '',
                  language: '',
                  timezone: ''
                })

                self.$localStorage.set('synchronized', 0)

                self.$localStorage.set('token', {
                  id: 0,
                  pk: ''
                })

                self.$localStorage.set('alwaysSync', true)

                self.$localStorage.set('iosTryInstall', true)

                self.$root.$data.trySync = true

                self.$router.push({ path: '/' })
              }
            })
          }
        })
    }
  }
}
</script>
