var months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

var simulator = {
  methods: {
    balanceHelper (simulation) {
      var days = { jan: 31, feb: 28, mar: 31, apr: 30, may: 31, jun: 30, jul: 30, aug: 31, sep: 30, oct: 31, nov: 30, dec: 31 }

      /*
      var biomass = {
        jan: [92.7, 30, 6, 275],
        feb: [193.95, 220, 45.6, 550],
        mar: [543.15, 228, 45.6, 1100],
        apr: [317.25, 211.6, 45.6, 1925],
        may: [227.7, 423.2, 45, 1650],
        jun: [231.3, 307.6, 44, 1595],
        jul: [234.9, 187.6, 15, 1375],
        aug: [53.2, 87.6, 4.5, 900],
        sep: [55.4, 40.5, 4, 675],
        oct: [50.4, 30.3, 3.5, 450],
        nov: [65.8, 55.8, 3, 405],
        dec: [87.5, 55.8, 2.5, 382.5]
      }
      */

      var biomassa = {
        natural: {
          baixa: {
            jan: [92.7, 30, 6],
            feb: [193.95, 220, 45.6],
            mar: [543.15, 228, 45.6],
            apr: [317.25, 211.6, 45.6],
            may: [227.7, 423.2, 45],
            jun: [231.3, 307.6, 44],
            jul: [234.9, 187.6, 15],
            aug: [53.2, 87.6, 4.5],
            sep: [55.4, 40.5, 4],
            oct: [50.4, 30.3, 3.5],
            nov: [65.8, 55.8, 3],
            dec: [87.5, 55.8, 2.5]
          },
          media: {
            jan: [2026, 482, 331],
            feb: [2349, 511, 351],
            mar: [2087, 297, 331],
            apr: [1637, 271, 361],
            may: [358, 312, 450],
            jun: [359, 313, 451],
            jul: [813, 336, 361],
            aug: [785, 332, 521],
            sep: [718, 298, 421],
            oct: [378, 323, 540],
            nov: [496, 448, 480],
            dec: [438, 395, 430]
          },
          alta: {
            jan: [1376, 700, 350],
            feb: [2411, 2744, 3078],
            mar: [1619, 2176, 2733],
            apr: [1904, 695, 2145],
            may: [1736, 1787, 51],
            jun: [1913, 277, 470],
            jul: [1364, 754, 144],
            aug: [1092, 408, 204],
            sep: [1011, 500, 250],
            oct: [652, 603, 49],
            nov: [667, 637, 30],
            dec: [1241, 643, 44]
          }
        },
        buffel: {
          baixa: {

            jan: [3641, 13745, 0],
            feb: [3641, 13745, 349],
            mar: [3641, 13745, 349],
            apr: [3641, 13745, 349],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [3641, 0, 0],
            dec: [3641, 13745, 0]
          },
          media: {
            jan: [4192, 1406, 0],
            feb: [4192, 1406, 300],
            mar: [4192, 1406, 300],
            apr: [4192, 1406, 300],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [4192, 0, 0],
            dec: [4192, 1406, 0]

          },
          alta: {
            jan: [5131, 1745, 0],
            feb: [5131, 1745, 428],
            mar: [5131, 1745, 428],
            apr: [5131, 1745, 428],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [5131, 0, 0],
            dec: [5131, 1745, 0]
          }
        },
        brachiaria: {
          baixa: {
            jan: [4225, 1400, 0],
            feb: [4225, 1400, 291],
            mar: [4225, 1400, 291],
            apr: [4225, 1400, 291],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [4225, 0, 0],
            nov: [4225, 0, 0],
            dec: [4225, 1400, 0]
          },
          media: {
            jan: [7103, 1807, 0],
            feb: [7103, 1807, 330],
            mar: [7103, 1807, 330],
            apr: [7103, 1807, 330],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [7103, 0, 0],
            nov: [7103, 0, 0],
            dec: [7103, 1807, 0]
          },
          alta: {
            jan: [6173, 1851, 0],
            feb: [6173, 1851, 202],
            mar: [6173, 1851, 0],
            apr: [6173, 1851, 0],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [6173, 0, 0],
            nov: [6173, 0, 0],
            dec: [6173, 1851, 0]
          }
        },
        andropogon: {
          baixa: {
            jan: [0, 0, 0],
            feb: [0, 0, 0],
            mar: [0, 0, 0],
            apr: [0, 0, 0],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [0, 0, 0],
            dec: [0, 0, 0]
          },
          media: {
            jan: [6105, 2072, 0],
            feb: [6105, 2072, 498],
            mar: [6105, 2072, 498],
            apr: [6105, 0, 498],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [6105, 0, 0],
            nov: [6105, 2072, 0],
            dec: [6105, 2072, 0]
          },
          alta: {
            jan: [9294, 2372, 0],
            feb: [9294, 2372, 332],
            mar: [9294, 2372, 332],
            apr: [9294, 0, 332],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [9294, 0, 0],
            nov: [9294, 2372, 0],
            dec: [9294, 2372, 0]
          }
        },
        panicum: {
          baixa: {
            jan: [4966, 900, 0],
            feb: [4966, 900, 300],
            mar: [4966, 900, 300],
            apr: [4966, 900, 300],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [4966, 0, 0],
            dec: [4966, 900, 0]
          },
          media: {
            jan: [5605, 1579, 0],
            feb: [5605, 1579, 310],
            mar: [5605, 1579, 310],
            apr: [5605, 1579, 310],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [4966, 0, 0],
            dec: [4966, 1579, 0]
          },
          alta: {
            jan: [5619, 1743, 0],
            feb: [5619, 1743, 326],
            mar: [5619, 1743, 326],
            apr: [5619, 1743, 326],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [5619, 0, 0],
            dec: [5619, 1743, 0]
          }
        },
        cynodon: {
          baixa: {
            jan: [0, 0, 0],
            feb: [1405, 522, 137],
            mar: [1405, 522, 0],
            apr: [1405, 0, 0],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [0, 0, 0],
            dec: [0, 0, 0]
          },
          media: {
            jan: [7103, 0, 0],
            feb: [7103, 1606, 336],
            mar: [7103, 1606, 0],
            apr: [7103, 0, 0],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [7103, 0, 0],
            nov: [7103, 0, 0],
            dec: [7103, 0, 0]
          },
          alta: {
            jan: [0, 0, 0],
            feb: [6967, 1372, 202],
            mar: [6967, 1372, 0],
            apr: [6967, 0, 0],
            may: [0, 0, 0],
            jun: [0, 0, 0],
            jul: [0, 0, 0],
            aug: [0, 0, 0],
            sep: [0, 0, 0],
            oct: [0, 0, 0],
            nov: [0, 0, 0],
            dec: [0, 0, 0]
          }
        }
      }

      var type = ['high', 'middle', 'low']
      var typePasture = ['natural', 'buffel', 'andropogon', 'cynodon', 'brachiaria', 'panicum']

      var pasture = {}

      var month, sum

      /*
      for (var i = 0; i < months.length; i++) {
        month = months[i]

        sum = 0
        for (var j = 0; j < type.length; j++) {
          sum += simulation['pasture_' + type[j]] * biomass[month][j]
        }
        pasture[month] = sum
      }
      */

     for (var i = 0; i < months.length; i++) {
      month = months[i]
      sum = 0
      for (var j = 0; j < type.length; j++) {
        for (var k = 0; k < typePasture.length; k++) {
          if (k === 0)
            sum += simulation['pasture_'  + type[j]] * biomassa[typePasture[k]][simulation.precipitation][month][j]
          if (k === 1)
             if (j === 1)
               sum += simulation['pasture_buffel'] * biomassa[typePasture[k]][simulation.precipitation][month][j]
          else
             sum += simulation['pasture_' + typePasture[k] + '_' + type[j]] * biomassa[typePasture[k]][simulation.precipitation][month][j]
        }
      }

      pasture[month] = sum
    }

      var pv = {
        goats_meat: [3.5, 3, 3.2],
        goats_milk: [4.5, 3, 3.2],
        cattl_meat: [3.5, 2, 2.9],
        cattl_milk: [3.5, 2.5, 2.9],
        sheep_meat: [3.1, 2.3, 2.9]
      }

      var races = ['goats_meat', 'goats_milk', 'cattl_meat', 'cattl_milk', 'sheep_meat']

      var category = ['matrizes', 'reprodutores', 'jovens']

      var daily = {}

      for (var y = 0; y < races.length; y++) {
        for (var z = 0; z < category.length; z++) {
          daily[races[y] + '_' + category[z]] = Math.round((
            simulation[races[y] + '_' + category[z]] *
            simulation[races[y] + '_' + category[z] + '_weight'] *
            pv[races[y]][z]) / 100)
        }
      }

      var consumption = {}

      for (var m = 0; m < months.length; m++) {
        month = months[m]

        consumption[month] = 0

        for (var w = 0; w < races.length; w++) {
          for (var x = 0; x < category.length; x++) {
            consumption[month] += daily[races[w] + '_' + category[x]] * days[month]
          }
        }
      }

      var balance = {}

      for (var n = 0; n < months.length; n++) {
        balance[months[n]] = Math.round(pasture[months[n]] - consumption[months[n]])
      }

      return balance
    },
    sourceHelper (simulation) {
      return 0.10 * simulation.source_palma + 0.30 * simulation.source_silagem + 0.35 * simulation.source_capineira + 0.90 * simulation.source_feno + 0.30 * simulation.source_proteina
    },
    finalStatsHelper (balance, source) {
      var stats = {}

      stats.lower = balance[months[0]]
      stats.critical = 0

      for (var m = 0; m < months.length; m++) {
        if (balance[months[m]] < stats.lower) {
          stats.lower = balance[months[m]]
        }

        if (balance[months[m]] < 0) {
          stats.critical += balance[months[m]]
        }
      }

      stats.final = stats.critical + source

      return stats
    }
  }
}

export default simulator
