<template>
  <v-app light>
    <v-app-bar bar clipped-left fixed dark color="primary">
      <v-btn icon @click.native="cancel">
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-toolbar-title v-text="$route.name" />

      <v-spacer />

      <beta />
    </v-app-bar>

    <v-main>
      <v-container fluid grid-list-lg>
        <v-row class="mt-14" justify="center">
          <div>
            <div class="d-md-none align-center">
              <img src="@/assets/img/stamp_partners.png" style="width: 300px">
            </div>
            <div class="d-none d-md-block align-center">
              <img src="@/assets/img/stamp_partners.png">
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col
            xs="12"
            sm="12"
            md="10"
            offset-md="1"
            lg="8"
            offset-lg="2"
            xl="6"
            offset-xl="3"
            class="my-3"
          >
            <v-card class="px-3 py-2">
              <v-card-text>
                <p>
                  O aplicativo <strong>Orçamento Forrageiro</strong> foi
                  desenvolvido pela
                  <a
                    href="http://embrapa.br/caprinos-e-ovinos"
                    target="_blank"
                  >Embrapa Caprinos e Ovinos</a>, em Sobral - CE, e pela
                  <a
                    href="http://embrapa.br/gado-de-corte"
                    target="_blank"
                  >Embrapa Gado de Corte</a>, em Campo Grande - MS, com o apoio da
                  <a href="http://www.cnabrasil.org.br" target="_blank">
                    Confederação da Agricultura e Pecuária do Brasil - CNA</a>. É mantido pelo
                  <a
                    href="http://please.cnpgc.embrapa.br"
                    target="_blank"
                  >Laboratory for Precision Livestock, Environment and
                    Software Engineering - PLEASE Lab</a>.
                </p>
                <p>
                  Para gerenciar seus dados armazenados nos servidores da
                  Embrapa, acesse a
                  <a
                    :href="settings.api.slice(0, -3)"
                    target="_blank"
                  >aplicação Web</a>
                  da plataforma <strong>Orçamento Forrageiro</strong>. Estes
                  dados são mantidos conforme estabelecido em nossa
                  <a
                    href="privacy-policy.html"
                    target="_blank"
                  >política de privacidade</a>.
                </p>
                <p>
                  Encontrou algum problema? Por favor, nos avise informando o
                  número da versão abaixo, a mensagem de erro e o modelo do seu
                  <em>smartphone</em> pelo nosso e-mail de suporte:.
                </p>
                <p class="text-xs-center">
                  <a
                    :href="
                      'mailto: ' +
                        settings.support +
                        '?subject=Problemas%20na%20vers%C3%A3o%20' +
                        version +
                        '%20do%20Or%C3%A7amento%20Forrageiro'
                    "
                    target="_blank"
                  >{{ settings.support }}</a>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            xs="12"
            sm="12"
            md="10"
            offset-md="1"
            lg="8"
            offset-lg="2"
            xl="6"
            offset-xl="3"
            class="my-3"
          >
            <p class="body-2 justify-center" style="text-align: center;">
              Versão {{ version }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Beta from '../components/Beta'

var pkg = require('../../package.json')

export default {
  components: {
    Beta
  },
  data () {
    return {
      version: pkg.version,
      settings: {
        support: process.env.VUE_APP_EMAIL,
        api: process.env.VUE_APP_API_URL
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
