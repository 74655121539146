<template>
  <v-app light>
    <v-app-bar app clipped-left fixed tabs dark color="primary">
      <v-btn icon @click.native="cancel">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-app-bar-title v-text="'Relatório: ' + original.simulation.label" />
      <v-spacer />
      <v-btn icon @click.native="gerarPDF()">
        <v-icon>mdi-download</v-icon>
      </v-btn>

      <v-tabs slot="extension" v-model="tab" grow>
        <v-tabs-slider color="white" />
        <v-tab> Saldo de Forragem </v-tab>
        <v-tab>
          {{ original.final &lt; 0 ? 'Equilibrar' : 'Otimizar' }}
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container fluid grid-list-lg>
            <v-row class="mt-10">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card
                  class="px-3 py-2"
                  dark
                  :color="original.final < 0 ? 'error' : 'success'"
                >
                  <v-card-title primary-title>
                    <div>
                      <div class="subheading">
                        Forragem
                      </div>
                      <div
                        class="headline"
                        v-html="original.final < 0 ? 'DEFICITÁRIA' : 'EXCEDENTE'"
                      />
                    </div>
                    <v-spacer />
                    <v-icon
                      right
                      large
                      v-html="original.final < 0 ? 'mood_bad' : 'mood'"
                    />
                  </v-card-title>
                  <v-card-text>
                    <p v-if="original.lower >= 0 && original.source == 0">
                      Parabéns! A propriedade tem forragem suficiente para
                      alimentar o rebanho. A propriedade tem, em média, um
                      <strong>excedente</strong> de
                      <strong>matéria seca</strong> de forragem nativa de
                      <strong>{{ original.lower | format }} Kg/mês</strong>.
                      Considere aumentar o rebanho para otimizar o uso da forragem
                      nativa disponível (você pode ajustar no próximo passo).
                    </p>
                    <p v-if="original.lower >= 0 && original.source > 0">
                      Parabéns! A propriedade tem forragem suficiente para
                      alimentar o rebanho. A propriedade tem, em média, um
                      <strong>excedente</strong> de forragem nativa de
                      <strong>{{ original.lower | format }} Kg/mês</strong>.
                      Existe ainda um excedente de outras fontes de forragem de
                      <strong>{{ original.source | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong>. Considere aumentar o rebanho
                      para otimizar o uso de toda forragem disponível (você pode
                      ajustar no próximo passo).
                    </p>
                    <p v-if="original.lower < 0 && original.final > 0">
                      Parabéns! A propriedade tem forragem suficiente para
                      alimentar o rebanho. Não existe forragem nativa suficiente,
                      porém existe um <strong>excedente</strong> de outras fontes
                      de forragem de
                      <strong>{{ original.final | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong>. Considere aumentar o rebanho
                      para otimizar o uso da forragem disponível (você pode
                      ajustar no próximo passo).
                    </p>
                    <p v-if="original.lower < 0 && original.final <= 0">
                      Atenção! A propriedade <strong>não</strong> tem forragem
                      suficiente para alimentar o rebanho. A forragem nativa e de
                      outras fontes são insuficientes para manter o rebanho
                      informado, acarretando em um déficit de
                      <strong>{{ original.final | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong> de forragem. Considere reduzir
                      o rebanho ou utilizar outras fontes de forragem (você pode
                      ajustar no próximo passo).
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid grid-list-lg>
            <v-row class="mt-10">
              <v-col sm="8" offset-sm="2" md="8" offset-md="2" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="px-3 py-2">
                  <v-card-title primary-title>
                    <div class="headline">
                      Relatório Mensal
                    </div>
                    <v-spacer class="hidden-md-and-down" />
                    <v-icon
                      right
                      large
                      class="hidden-md-and-down"
                    >
                      calendar_today
                    </v-icon>
                  </v-card-title>
                  <v-card-text class="mb-0 pb-0">
                    <p>
                      Veja abaixo, mês a mês, a
                      <strong>disponibilidade de pasto nativo e cultivado </strong>
                      (em <strong>matéria seca</strong>) nesta propriedade para
                      alimentar o rebanho:
                    </p>
                  </v-card-text>
                  <template v-for="(month, i) in months">
                    <v-card-title :key="i">
                      <div class="subheading">
                        {{ labels[i] }}
                      </div>
                      <v-spacer />
                      <v-chip
                        label
                        :color="
                          original.balance[month] >= 0 ? 'success' : 'error'
                        "
                        text-color="white"
                      >
                        <v-icon
                          left
                          v-html="
                            original.balance[month] >= 0
                              ? 'done'
                              : 'error_outline'
                          "
                        />
                        <strong>{{ original.balance[month] | format }} Kg</strong>
                      </v-chip>
                    </v-card-title>
                    <v-divider
                      v-if="i + 1 < months.length"
                      :key="`divider-${i}`"
                    />
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid grid-list-lg>
            <v-row class="mt-10">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-subheader>Simulação Original</v-subheader>
                <v-card class="px-3 py-2">
                  <v-card-title
                    primary-title
                    :style="
                      'color: ' + (original.final < 0 ? '#FF5252' : '#4CAF50')
                    "
                  >
                    <div>
                      <div class="subheading">
                        Forragem
                      </div>
                      <div
                        class="headline"
                        v-html="original.final < 0 ? 'DEFICITÁRIA' : 'EXCEDENTE'"
                      />
                    </div>
                    <v-spacer />
                    <v-icon
                      right
                      large
                      v-html="original.final < 0 ? 'mood_bad' : 'mood'"
                      :color="original.final < 0 ? 'error' : 'success'"
                    />
                  </v-card-title>
                  <v-card-text>
                    <p>
                      Simule abaixo ajustes no <strong>rebanho</strong> e em
                      <strong>outras fontes</strong> para
                      {{ original.final &lt; 0 ? 'equilibrar' : 'otimizar' }} o
                      {{ original.final &lt; 0 ? 'déficit' : 'excedente' }} de
                      forragem de
                      <strong>{{ original.lower &lt; 0 ? original.final : original.lower | format }}
                        {{ original.lower &lt; 0 ? 'Kg/ano' : 'Kg/mês' }}</strong>
                      em <strong>matéria seca</strong>.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid grid-list-lg>
            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-subheader>Simulação Ajustada</v-subheader>
                <v-card class="px-3 py-2">
                  <v-card-title
                    primary-title
                    :style="
                      'color: ' + (changed.final < 0 ? '#FF5252' : '#4CAF50')
                    "
                  >
                    <div>
                      <div class="subheading">
                        Forragem
                      </div>
                      <div
                        class="headline"
                        v-html="changed.final < 0 ? 'DEFICITÁRIA' : 'EXCEDENTE'"
                      />
                    </div>
                    <v-spacer />
                    <v-icon
                      right
                      large
                      v-html="changed.final < 0 ? 'mood_bad' : 'mood'"
                      :color="changed.final < 0 ? 'error' : 'success'"
                    />
                  </v-card-title>
                  <v-card-text>
                    <p v-if="changed.lower >= 0 && changed.source == 0">
                      Parabéns! Na <strong>simulação ajustada</strong> a
                      propriedade tem forragem suficiente para alimentar o
                      rebanho. A propriedade tem, em média, um
                      <strong>excedente</strong> de
                      <strong>matéria seca</strong> de forragem nativa de
                      <strong>{{ changed.lower | format }} Kg/mês</strong>.
                      Considere aumentar o rebanho para otimizar o uso da forragem
                      nativa disponível.
                    </p>
                    <p v-if="changed.lower >= 0 && changed.source > 0">
                      Parabéns! Na <strong>simulação ajustada</strong> a
                      propriedade tem forragem suficiente para alimentar o
                      rebanho. A propriedade tem, em média, um
                      <strong>excedente</strong> de
                      <strong>matéria seca</strong> de forragem nativa de
                      <strong>{{ changed.lower | format }} Kg/mês</strong>. Existe
                      ainda um excedente de outras fontes de forragem de
                      <strong>{{ changed.source | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong>. Considere aumentar o rebanho
                      para otimizar o uso da forragem nativa disponível.
                    </p>
                    <p v-if="changed.lower < 0 && changed.final > 0">
                      Parabéns! Na <strong>simulação ajustada</strong> a
                      propriedade tem forragem suficiente para alimentar o
                      rebanho. Não existe forragem nativa suficiente, porém existe
                      um <strong>excedente</strong> de outras fontes de forragem
                      de <strong>{{ changed.final | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong>. Considere aumentar o rebanho
                      para otimizar o uso da forragem disponível.
                    </p>
                    <p v-if="changed.lower < 0 && changed.final <= 0">
                      Atenção! Na <strong>simulação ajustada</strong> a
                      propriedade <strong>não</strong> tem forragem suficiente
                      para alimentar o rebanho. A forragem nativa e de outras
                      fontes são insuficientes para manter o rebanho informado,
                      acarretando em um déficit de
                      <strong>{{ changed.final | format }} Kg/ano</strong> de
                      <strong>matéria seca</strong> de forragem. Considere reduzir
                      o rebanho ou utilizar outras fontes de forragem.
                    </p>
                  </v-card-text>
                  <template v-if="changed.lower < 0 && changed.final < 0">
                    <v-divider />
                    <v-card-text>
                      <p>
                        Você irá equilibrar o saldo negativo se introduzir na
                        alimentação do rebanho as seguintes quantidades de
                        forragem em <strong>matéria natural</strong>:
                        <strong>{{ (changed.final / 0.1) | format }} Kg/ano de
                          PALMA</strong>
                        <u>ou</u>
                        <strong>{{ (changed.final / 0.3) | format }} Kg/ano de
                          SILAGEM</strong>
                        <u>ou</u>
                        <strong>{{ (changed.final / 0.35) | format }} Kg/ano de
                          CAPINEIRA</strong>
                        <u>ou</u>
                        <strong>{{ (changed.final / 0.9) | format }} Kg/ano de
                          FENO</strong>.
                      </p>
                    </v-card-text>
                  </template>
                  <v-divider />
                  <v-card-text class="mb-0 pb-0">
                    <p>
                      Utilize os botões abaixo para <strong>ajustar</strong> sua
                      simulação original. É possível efetuar ajustes no
                      <strong>rebanho</strong> e nas
                      <strong>outras fontes de forragem</strong>, respectivamente.
                    </p>
                  </v-card-text>
                  <v-card-actions class="mt-0 pt-0">
                    <v-row class="mx-1">
                      <v-col xs="12" md="6" class="px-1 py-1">
                        <v-btn
                          block
                          color="brown darken-1"
                          dark
                          @click.native="openHerd()"
                        >
                          <v-icon left dark>
                            pets
                          </v-icon>
                          Rebanho
                        </v-btn>
                      </v-col>
                      <v-col xs="12" md="6" class="px-1 py-1">
                        <v-btn
                          block
                          color="teal darken-1"
                          dark
                          @click.native="openSources()"
                        >
                          <v-icon left dark>
                            inbox
                          </v-icon>
                          Outras Fontes
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-divider v-if="adjusted" />
                  <v-card-actions v-if="adjusted">
                    <v-row>
                      <v-col
                        xs="12"
                        sm="12"
                        md="10"
                        offset-md="1"
                        lg="8"
                        offset-lg="2"
                        xl6
                        offset-xl="3"
                      >
                        <v-btn
                          block
                          color="primary"
                          dark
                          large
                          @click.native="saveAdjusted()"
                        >
                          <v-icon left dark>
                            whatshot
                          </v-icon>
                          Salvar Ajustes
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
    <v-dialog
      v-model="changeHerd"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar dark color="brown darken-1">
          <v-btn icon dark @click.native="closeHerd()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-app-bar-title>Ajustar Rebanho</v-app-bar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click.native="applyHerd()">
              Aplicar
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
        <v-form ref="formHerd" lazy-validation>
          <v-row>
            <v-col xs="12">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="(h, i) in herd" :key="i">
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12" class="mt-2" style="float: left">
                          {{ h.label }}
                        </v-col>
                        <v-col cols="12" class="mr-2">
                          <v-chip
                            label
                            small
                            style="float: right"
                            v-if="sumHerd(h.type) > 0"
                          >
                            {{ sumHerd(h.type) | format }} animais
                          </v-chip>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-text class="mb-0 pb-0">
                        Ajuste a
                        <strong>quantidade máxima (#)</strong> de cabeças no
                        <strong>ano</strong> e o
                        <strong>peso médio (em Kg)</strong> para cada tipo de
                        animal do seu rebanho desta propriedade:
                      </v-card-text>
                      <v-card-text class="my-0 py-0">
                        <v-container fluid>
                          <v-row v-for="(t, j) in type" :key="j">
                            <v-col
                              xs="12"
                              sm="12"
                              md="2"
                              lg="2"
                              xl="2"
                              class="pr-2 text-xs-left text-md-right pt-3 pb-3"
                            >
                              <h3
                                class="subheading mx-0 px-0"
                                style="text-transform: capitalize"
                              >
                                {{ t }}:
                              </h3>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="pr-1">
                              <v-text-field
                                v-model.number="auxHerd[h.type + '_' + t]"
                                :rules="rules.number"
                                suffix="#"
                                single-line
                                outlined
                                :rows="1"
                                @focus="$event.target.select()"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
                              <v-text-field
                                v-model.number="
                                  auxHerd[h.type + '_' + t + '_weight']
                                "
                                :rules="rules.number"
                                suffix="Kg"
                                single-line
                                outlined
                                :rows="1"
                                @focus="$event.target.select()"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="mt-2 pt-2">
          <v-row class="mx-1">
            <v-col xs="12" md="6" class="px-1 py-1">
              <v-btn block color="error" dark @click.native="closeHerd()">
                <v-icon left dark>
                  cancel
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col xs="12" md="6" class="px-1 py-1">
              <v-btn block color="success" dark @click.native="applyHerd()">
                <v-icon left dark>
                  done
                </v-icon>
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="changeSources"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar dark color="teal darken-1">
          <v-btn icon dark @click.native="closeSources()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Ajustar Outras Fontes</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click.native="applySources()">
              Aplicar
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
        <v-form ref="formSources" lazy-validation>
          <v-row class="pa-3">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="3"
              class="px-2"
              v-for="(s, z) in sources"
              :key="z"
            >
              <v-text-field
                :label="s.label"
                v-model.number="auxSources['source_' + s.name]"
                :rules="rules.number"
                suffix="Kg"
              />
            </v-col>
          </v-row>
          <v-row class="pa-3" no-gutters>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="flex px-2 py-2  text-center text-md-left text-sm-left text-lg-left text-xl-left"
            >
              <v-chip label color="teal" text-color="white" align-center>
                <v-icon left>
                  nature
                </v-icon>
                <strong>{{ totalNatural | format }} Kg/ano</strong>
                &nbsp;de&nbsp;
                <strong>Matéria Natural</strong>
              </v-chip>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="flex px-2 py-2  text-center text-md-right text-sm-right text-lg-right text-xl-right"
            >
              <v-chip label color="blue-grey" text-color="white" align-center>
                <v-icon left>
                  inbox
                </v-icon>
                <strong>{{ totalDry | format }} Kg/ano</strong>
                &nbsp;de&nbsp;
                <strong>Matéria Seca</strong>
              </v-chip>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="mt-2 pt-2">
          <v-row class="mx-1">
            <v-col cols="12" xs="12" md="6" class="px-1 py-1">
              <v-btn block color="error" dark @click.native="closeSources()">
                <v-icon left dark>
                  cancel
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" md="6" class="px-1 py-1">
              <v-btn block color="success" dark @click.native="applySources()">
                <v-icon left dark>
                  done
                </v-icon>
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-wrapper ref="confirm" />

    <message-wrapper ref="message" />

    <v-snackbar
      :timeout="2000"
      color="success"
      :multi-line="true"
      :vertical="false"
      v-model="snackbar"
    >
      Ajustes salvos com sucesso!
      <v-btn dark text @click.native="snackbar = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import timestamp from 'unix-timestamp'

import UtilHelper from '../helpers/util'
import SimulatorHelper from '../helpers/simulator'
import FigureHelper from '../helpers/figures'

import ConfirmWrapper from '../components/Confirm.vue'
import MessageWrapper from '../components/Message.vue'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  components: {
    ConfirmWrapper,
    MessageWrapper
  },
  mixins: [UtilHelper, SimulatorHelper, FigureHelper],
  data () {
    return {
      tab: null,
      original: {
        simulation: null,
        balance: [],
        source: null,
        lower: 0,
        critical: 0,
        final: 0
      },
      changed: {
        simulation: null,
        balance: [],
        source: null,
        lower: 0,
        critical: 0,
        final: 0
      },
      months: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ],
      labels: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      changeHerd: false,
      changeSources: false,
      adjusted: false,
      snackbar: false,
      precipitations: [
        { type: 'baixa', label: 'Entre 0 e 300mm/ano' },
        { type: 'media', label: 'Entre 300 e 600mm/ano' },
        { type: 'alta', label: 'Mais de 600mm/ano' }
      ],

      herd: [
        { type: 'goats_meat', label: 'Caprinos de Corte' },
        { type: 'goats_milk', label: 'Caprinos de Leite' },
        { type: 'sheep_meat', label: 'Ovinos de Corte' },
        { type: 'cattl_meat', label: 'Gado de Corte' },
        { type: 'cattl_milk', label: 'Gado de Leite' }
      ],
      type: ['matrizes', 'reprodutores', 'jovens'],
      sources: [
        { label: 'Palma', name: 'palma' },
        { label: 'Silagem', name: 'silagem' },
        { label: 'Feno', name: 'feno' },
        { label: 'Carpineira', name: 'capineira' },
        { label: 'Banco de Proteínas', name: 'proteina' }
      ],

      auxHerd: {},
      auxSources: null,
      imgData: null,
      rules: {
        number: [
          (v) => ((v || v === 0) && !isNaN(v)) || 'Insira um número inteiro!'
        ]
      }
    }
  },
  beforeCreate () {
    /*
    if (!this.$localStorage.get('user').authenticated) {
      this.$router.push('/')
    }
    */
  },
  beforeMount () {
    this.original.simulation = this.instantiateSimulationHelper()
    this.auxSources = this.instantiateAuxSourcesHelper()
    this.imgData = this.instantiateImagePDF()
    var self = this

    this.months.forEach((e) => {
      self.original.balance[e] = 0
    })

    this.changed = JSON.parse(JSON.stringify(this.original))
  },
  mounted () {
    var self = this

    this.$db.simulation.get(this.$route.params.code, function (s) {
      self.original.simulation = s

      self.run(self.original)

      self.changed = JSON.parse(JSON.stringify(self.original))
    })
  },
  methods: {
    gerarPDF () {
      const doc = new JsPDF()

      doc.addImage(this.imgData, 'PNG', 10, 10, 25, 25)

      doc.autoTable({
        body: [
          ['Nome Simulação: ' + this.original.simulation.label, 'Propriedade   : ' + this.original.simulation.farm, 'Tamanho       : ' + this.original.simulation.size + ' ha'],
          ['Estado        : ' + this.original.simulation.state, 'Cidade        : ' + this.original.simulation.city, 'Precipitação  : ' + this.precipitationLabel(this.original.simulation.precipitation)]
        ],
        margin: { top: 20, left: 40 }
      })

      const wantedTableWidth = 100
      const pageWidth = doc.internal.pageSize.width
      const margin = (pageWidth - wantedTableWidth) / 2

      doc.autoTable({
        head: [['Pastagem', 'Baixa Oferta', 'Média Oferta', 'Alta Oferta']],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' }
        },
        margin: { left: margin, right: margin },

        body: [
          ['Natural', this.original.simulation.pasture_low, this.original.simulation.pasture_middle, this.original.simulation.pasture_high],
          ['Buffel', this.original.simulation.pasture_buffel_low, this.original.simulation.pasture_buffel, this.original.simulation.pasture_buffel_high],
          ['Panicum', this.original.simulation.pasture_panicum_low, this.original.simulation.pasture_panicum_middle, this.original.simulation.pasture_panicum_high],
          ['Brachiaria', this.original.simulation.pasture_brachiaria_low, this.original.simulation.pasture_brachiaria_middle, this.original.simulation.pasture_brachiaria_high],
          ['Andropogon', this.original.simulation.pasture_andropogon_low, this.original.simulation.pasture_andropogon_middle, this.original.simulation.pasture_andropogon_high],
          ['Cynodon', this.original.simulation.pasture_cynodon_low, this.original.simulation.pasture_cynodon_middle, this.original.simulation.pasture_cynodon_high]
        ]

      })

      doc.autoTable({
        head: [['Rebanho', 'Matrizes (#)', 'Matrizes (kg)', 'Reprodutores (#)', 'Reprodutores (kg)', 'Jovens (#)', 'Jovens (kg)']],
        body: [
          ['Caprinos de Corte', this.original.simulation.goats_meat_matrizes, this.original.simulation.goats_meat_matrizes_weight, this.original.simulation.goats_meat_reprodutores, this.original.simulation.goats_meat_reprodutores_weight, this.original.simulation.goats_meat_jovens, this.original.simulation.goats_meat_jovens_weight],
          ['Caprinos de Leite', this.original.simulation.goats_milk_matrizes, this.original.simulation.goats_milk_matrizes_weight, this.original.simulation.goats_milk_reprodutores, this.original.simulation.goats_milk_reprodutores_weight, this.original.simulation.goats_milk_jovens, this.original.simulation.goats_milk_jovens_weight],
          ['Ovinos de Corte', this.original.simulation.sheep_meat_matrizes, this.original.simulation.sheep_meat_matrizes_weight, this.original.simulation.sheep_meat_reprodutores, this.original.simulation.sheep_meat_reprodutores_weight, this.original.simulation.sheep_meat_jovens, this.original.simulation.sheep_meat_jovens_weight],
          ['Gado de Corte', this.original.simulation.cattl_meat_matrizes, this.original.simulation.cattl_meat_matrizes_weight, this.original.simulation.cattl_meat_reprodutores, this.original.simulation.cattl_meat_reprodutores_weight, this.original.simulation.cattl_meat_jovens, this.original.simulation.cattl_meat_jovens_weight],
          ['Gado de Leite', this.original.simulation.cattl_milk_matrizes, this.original.simulation.cattl_milk_matrizes_weight, this.original.simulation.cattl_milk_reprodutores, this.original.simulation.cattl_milk_reprodutores_weight, this.original.simulation.cattl_milk_jovens, this.original.simulation.cattl_milk_jovens_weight]

        ],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
          5: { halign: 'center' },
          6: { halign: 'center' }

        }

      })
      doc.autoTable({
        head: [
          ['Fontes', '', '', '', ''],
          ['Palma', 'Silagem', 'Feno', 'Capineira', 'Proteína']],
        body: [
          [this.original.simulation.source_palma, this.original.simulation.source_silagem, this.original.simulation.source_feno, this.original.simulation.source_capineira, this.original.simulation.source_proteina]
        ],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' }
        },
        margin: { left: margin, right: margin }

      })

      if (this.original.final < 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [255, 0, 0] } }, // Cells in first column centered and green
          margin: { top: 20 },
          body: [
            ['FORRAGEM DEFICITÁRIA']
          ]
        })
      }

      if (this.original.final >= 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 20 },
          body: [
            ['FORRAGEM EXCEDENTE']
          ]
        })
      }

      doc.autoTable({
        styles: { fillColor: [255, 0, 0] },
        margin: { top: 40 }
      })

      if (this.original.lower >= 0 && this.original.source === 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { margin: { top: 50 }, halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 40 },
          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. A propriedade tem, em média, um excedente de matéria seca de forragem nativa ' + this.original.lower + ' Kg/mês Considere aumentar o rebanho para otimizar o uso da forragem nativa disponível.']
          ]
        })
      }

      if (this.original.lower >= 0 && this.original.source > 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 40 },

          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. A propriedade tem, em média, um excedente de forragem nativa de ' + this.original.lower + ' Kg/mês.  Existe ainda um excedente de outras fontes de forragem de ' + this.original.source + ' Kg/ano de matéria seca. Considere aumentar o rebanho para otimizar o uso de toda forragem disponível.']
          ]
        })
      }

      if (this.original.lower < 0 && this.original.final > 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 40 },
          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. Não existe forragem nativa suficiente, porém existe um excedente de outras fontes de forragem de ' + this.original.final + ' Kg/ano de matéria seca. Considere aumentar o rebanho para otimizar o uso da forragem disponível']
          ]
        })
      }

      if (this.original.lower < 0 && this.original.final <= 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [255, 0, 0] } }, // Cells in first column centered and green
          margin: { top: 40 },
          body: [
            ['Atenção! A propriedade não tem forragem suficiente para alimentar o rebanho. A forragem nativa e de outras fontes são insuficientes para manter o rebanho informado, acarretando em um déficit de ' + this.original.final + ' Kg/ano de matéria seca de forragem. Considere reduzir o rebanho ou utilizar outras fontes de forragem.']
          ]
        })
      }

      doc.addPage()
      doc.addImage(this.imgData, 'PNG', 10, 10, 25, 25)

      doc.autoTable({
        head: [
          ['Relatório Mensal', ''],
          ['Mês', 'Disponibilidade']],
        body: [
          ['Janeiro', this.original.balance.jan],
          ['Fevereiro', this.original.balance.feb],
          ['Março', this.original.balance.mar],
          ['Abril', this.original.balance.apr],
          ['Maio', this.original.balance.may],
          ['Junho', this.original.balance.jun],
          ['Julho', this.original.balance.jul],
          ['Agosto', this.original.balance.aug],
          ['Setembro', this.original.balance.sep],
          ['Outubro', this.original.balance.oct],
          ['Novembro', this.original.balance.nov],
          ['Dezembro', this.original.balance.dec]
        ],
        margin: { left: margin, right: margin },

        columnStyles: {
          0: { halign: 'center', valign: 'middle' },
          1: { halign: 'center', valign: 'middle' }
        },
        didParseCell: function (data) {
          if (data.section === 'body' && data.cell.raw < 0) {
            data.cell.styles.textColor = 'red'
          }
          if (data.section === 'body' && data.cell.raw >= 0) {
            data.cell.styles.textColor = 'green'
          }
        }

      })

      doc.autoTable({
        head: [['Rebanho', 'Matrizes (#)', 'Matrizes (kg)', 'Reprodutores (#)', 'Reprodutores (kg)', 'Jovens (#)', 'Jovens (kg)']],
        body: [
          ['Caprinos de Corte', this.changed.simulation.goats_meat_matrizes, this.changed.simulation.goats_meat_matrizes_weight, this.changed.simulation.goats_meat_reprodutores, this.changed.simulation.goats_meat_reprodutores_weight, this.changed.simulation.goats_meat_jovens, this.changed.simulation.goats_meat_jovens_weight],
          ['Caprinos de Leite', this.changed.simulation.goats_milk_matrizes, this.changed.simulation.goats_milk_matrizes_weight, this.changed.simulation.goats_milk_reprodutores, this.changed.simulation.goats_milk_reprodutores_weight, this.changed.simulation.goats_milk_jovens, this.changed.simulation.goats_milk_jovens_weight],
          ['Ovinos de Corte', this.changed.simulation.sheep_meat_matrizes, this.changed.simulation.sheep_meat_matrizes_weight, this.changed.simulation.sheep_meat_reprodutores, this.changed.simulation.sheep_meat_reprodutores_weight, this.changed.simulation.sheep_meat_jovens, this.changed.simulation.sheep_meat_jovens_weight],
          ['Gado de Corte', this.changed.simulation.cattl_meat_matrizes, this.changed.simulation.cattl_meat_matrizes_weight, this.changed.simulation.cattl_meat_reprodutores, this.changed.simulation.cattl_meat_reprodutores_weight, this.changed.simulation.cattl_meat_jovens, this.changed.simulation.cattl_meat_jovens_weight],
          ['Gado de Leite', this.changed.simulation.cattl_milk_matrizes, this.changed.simulation.cattl_milk_matrizes_weight, this.changed.simulation.cattl_milk_reprodutores, this.changed.simulation.cattl_milk_reprodutores_weight, this.changed.simulation.cattl_milk_jovens, this.changed.simulation.cattl_milk_jovens_weight]

        ],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
          5: { halign: 'center' },
          6: { halign: 'center' }

        },
        headStyles: {
          fillColor: '#0d47a1'
        }
      })

      doc.autoTable({
        head: [
          ['Fontes', '', '', '', ''],
          ['Palma', 'Silagem', 'Feno', 'Capineira', 'Proteína']],
        body: [
          [this.changed.simulation.source_palma, this.changed.simulation.source_silagem, this.changed.simulation.source_feno, this.changed.simulation.source_capineira, this.changed.simulation.source_proteina]
        ],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' }
        },
        margin: { left: margin, right: margin }

      })

      if (this.changed.lower >= 0 && this.changed.source === 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. A propriedade tem, em média, um excedente de matéria seca de forragem nativa ' + this.changed.lower + ' Kg/mês Considere aumentar o rebanho para otimizar o uso da forragem nativa disponível.']
          ]
        })
      }

      if (this.changed.lower >= 0 && this.changed.source > 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. A propriedade tem, em média, um excedente de forragem nativa de ' + this.changed.lower + ' Kg/mês.  Existe ainda um excedente de outras fontes de forragem de ' + this.changed.source + ' Kg/ano de matéria seca. Considere aumentar o rebanho para otimizar o uso de toda forragem disponível.']
          ]
        })
      }

      if (this.changed.lower < 0 && this.changed.final > 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
          body: [
            ['Parabéns! A propriedade tem forragem suficiente para alimentar o rebanho. Não existe forragem nativa suficiente, porém existe um excedente de outras fontes de forragem de ' + this.changed.final + ' Kg/ano de matéria seca. Considere aumentar o rebanho para otimizar o uso da forragem disponível']
          ]
        })
      }

      if (this.changed.lower < 0 && this.changed.final <= 0) {
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [255, 0, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
          body: [
            ['Atenção! A propriedade não tem forragem suficiente para alimentar o rebanho. A forragem nativa e de outras fontes são insuficientes para manter o rebanho informado, acarretando em um déficit de ' + this.changed.final + ' Kg/ano de matéria seca de forragem. Considere reduzir o rebanho ou utilizar outras fontes de forragem.']
          ]
        })
      }

      doc.save('relatorio.pdf')
    },
    cancel () {
      this.$router.push({ path: '/home' })
    },
    run (s) {
      s.balance = this.balanceHelper(s.simulation)
      s.source = this.sourceHelper(s.simulation)

      s.lower = s.balance[this.months[0]]
      s.critical = 0

      for (var m = 0; m < this.months.length; m++) {
        if (s.balance[this.months[m]] < s.lower) {
          s.lower = s.balance[this.months[m]]
        }

        if (s.balance[this.months[m]] < 0) {
          s.critical += s.balance[this.months[m]]
        }
      }

      s.final = s.critical + s.source
    },
    sumHerd (type) {
      var self = this

      var sum = 0
      this.type.forEach((t) => {
        sum += self.auxHerd[type + '_' + t]
      })

      return sum
    },
    precipitationLabel (type) {
      for (var i = 0; i < this.precipitations.length; i++) {
        if (this.precipitations[i].type === type) {
          return this.precipitations[i].label
        }
      }
    },

    openHerd () {
      var self = this

      self.herd.forEach((h) => {
        self.type.forEach((t) => {
          self.auxHerd[h.type + '_' + t] =
            self.changed.simulation[h.type + '_' + t]
          self.auxHerd[h.type + '_' + t + '_weight'] =
            self.changed.simulation[h.type + '_' + t + '_weight']
        })
      })

      this.changeHerd = true
    },
    openSources () {
      var self = this

      self.sources.forEach((s) => {
        self.auxSources['source_' + s.name] =
          self.changed.simulation['source_' + s.name]
      })

      this.changeSources = true
    },
    applyHerd () {
      if (this.$refs.formHerd.validate()) {
        var self = this

        self.herd.forEach((h) => {
          self.type.forEach((t) => {
            self.changed.simulation[h.type + '_' + t] =
              self.auxHerd[h.type + '_' + t]
            self.changed.simulation[h.type + '_' + t + '_weight'] =
              self.auxHerd[h.type + '_' + t + '_weight']
          })
        })

        this.adjusted = true

        this.run(this.changed)

        this.changeHerd = false
      }
    },
    applySources () {
      if (this.$refs.formSources.validate()) {
        var self = this

        self.sources.forEach((s) => {
          self.changed.simulation['source_' + s.name] =
            self.auxSources['source_' + s.name]
        })

        this.adjusted = true

        this.run(this.changed)

        this.changeSources = false
      }
    },
    closeHerd () {
      var self = this

      this.$refs.confirm
        .open(
          'Cancelar Ajustes',
          'Tem certeza de que deseja sair? Os ajustes em REBANHO serão perdidos!',
          { color: 'brown darken-1' }
        )
        .then((confirm) => {
          if (confirm) {
            self.changeHerd = false
          }
        })
    },
    closeSources () {
      var self = this

      this.$refs.confirm
        .open(
          'Cancelar Ajustes',
          'Tem certeza de que deseja sair? Os ajustes em OUTRAS FONTES serão perdidos!',
          { color: 'teal darken-1' }
        )
        .then((confirm) => {
          if (confirm) {
            self.changeSources = false
          }
        })
    },
    saveAdjusted () {
      var self = this

      this.$refs.confirm
        .open(
          'Salvar Ajustes',
          'Tem certeza de que deseja salvar PERMANENTEMENTE os ajustes na simulação? Esta ação é irreversível!',
          { color: 'primary' }
        )
        .then((confirm) => {
          if (confirm) {
            self.original = JSON.parse(JSON.stringify(self.changed))

            self.original.simulation.updated = timestamp.fromDate(new Date())

            self.run(self.original)

            self.$db.simulation
              .where('code')
              .equals(self.original.simulation.code)
              .modify(self.original.simulation)
              .then(() => {
                self.adjusted = false
                self.snackbar = true
              })
          }
        })
    }
  },
  filters: {
    round: function (value) {
      if (!value) return ''

      return Math.ceil(value)
    },
    format: function (value) {
      return Number(Math.round(value)).toLocaleString('pt-BR')
    }
  },
  computed: {
    totalNatural () {
      return (
        this.auxSources.source_palma +
        this.auxSources.source_silagem +
        this.auxSources.source_feno +
        this.auxSources.source_capineira +
        this.auxSources.source_proteina

      )
    },
    totalDry () {
      return (
        0.1 * this.auxSources.source_palma +
        0.3 * this.auxSources.source_silagem +
        0.35 * this.auxSources.source_capineira +
        0.9 * this.auxSources.source_feno +
        0.3 * this.auxSources.source_proteina
      )
    }

  }
}
</script>
