<template>
  <router-view />
</template>

<script>

export default {
  data () {
    return {
    }
  },
  localStorage: {
    email: {
      type: String,
      default: ''
    },
    reliable: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: {
        authenticated: false,
        id: 0,
        login: '',
        name: '',
        email: '',
        picture: '',
        language: '',
        timezone: '',
        token: ''
      }
    },
    synchronized: {
      type: Number,
      default: 0
    },
    alwaysSync: {
      type: Boolean,
      default: true
    },
    iosTryInstall: {
      type: Boolean,
      default: true
    }
  }
}
</script>
