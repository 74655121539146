<template>
  <v-app light>
    <v-app-bar app clipped-left fixed dark color="primary">
      <v-btn icon @click.native="cancel">
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-app-bar-title v-text="simulation.label" />

      <v-spacer />

      <v-btn outlined dark class="mr-3 hidden-sm-and-down" @click.native="save">
        <v-icon left>
          save
        </v-icon>
        Salvar
      </v-btn>
      <v-btn
        outlined
        dark
        class="mr-3 ml-0 hidden-sm-and-down"
        @click.native="cancel"
      >
        <v-icon left>
          cancel
        </v-icon>
        Cancelar
      </v-btn>
      <v-btn icon dark class="hidden-md-and-up" @click.native="save">
        <v-icon>save</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-stepper v-model="step" alt-labels style="background-color: #fafafa">
        <v-stepper-header style="background-color: #fff">
          <v-stepper-step
            step="1"
            :complete="step > 1"
            :rules="[(v) => validateHelper(simulation).step1]"
          >
            Dados Básicos
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="2"
            :complete="step > 2"
            :rules="[(v) => validateHelper(simulation).step2]"
          >
            Pastagem Natural
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="3"
            :complete="step > 3"
            :rules="[(v) => validateHelper(simulation).step3]"
          >
            Rebanho
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="4" :complete="step > 4">
            Outras Fontes
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-content class="pa-3" step="1">
          <v-form ref="form1" lazy-validation>
            <v-row no-gutters class="pa-3">
              <v-col xs="12" class="mb-2 px-2">
                <h3 class="headline">
                  Dados Básicos
                </h3>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-3">
              <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="px-2">
                <v-text-field
                  label="Nome da Simulação"
                  v-model="simulation.label"
                  :rules="rules.name"
                  :counter="15"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="px-2">
                <v-text-field
                  label="Nome da Propriedade"
                  v-model="simulation.farm"
                  :rules="rules.farm"
                  :counter="50"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="px-2">
                <v-text-field
                  label="Tamanho da Propriedade"
                  v-model.number="simulation.size"
                  :rules="rules.size"
                  suffix="ha"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-select
                  :items="states()"
                  item-text="name"
                  item-value="uf"
                  v-model="simulation.state"
                  label="Estado"
                  append-icon="map"
                  cache-items
                  :rules="rules.state"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-select
                  :items="cities"
                  v-model="simulation.city"
                  label="Cidade"
                  append-icon="location_on"
                  :rules="rules.city"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                <div><strong>Média Anual de Precipitação</strong></div>

                <v-radio-group v-model="simulation.precipitation" row mandatory>
                  <v-radio
                    v-for="(p, i) in precipitations"
                    :key="i"
                    :label="p.label"
                    :value="p.type"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col xs="6" class="pr-1">
              <v-btn block color="error" @click.native="cancel">
                <v-icon left dark>
                  close
                </v-icon>
                Sair
              </v-btn>
            </v-col>
            <v-col xs="6" class="pl-1">
              <v-btn block color="success" @click.native="next">
                Próximo
                <v-icon right dark>
                  navigate_next
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content class="pa-3" step="2">
          <v-form ref="form2" lazy-validation>
            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Pastagem Natural
                </h3>
              </v-col>
            </v-row>
            <v-row class="pa-3">
              <v-col cols="12" sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="px-2 py-2">
                Utilize as imagens abaixo para classificar o
                <strong>pasto nativo e pasto cultivado</strong> das áreas utilizadas para pastejo
                dos animais:
              </v-col>
              <v-col
                cols="12"
                sm="10"
                offset-sm="1"
                md="10"
                offset-md="1"
                lg="8"
                offset-lg="2"
                xl="6"
                offset-xl="3"
                class="px-2 py-2 text-lg-right text-xs-center text-lg-center text-sm-center text-xl-center text-md-center"
              >
                <v-chip
                  label
                  :color="
                    totalPasture > 0 && totalPasture <= simulation.size
                      ? 'success'
                      : 'error'
                  "
                  text-color="white"
                  align-center
                >
                  <v-icon
                    left
                    v-html="
                      totalPasture > 0 && totalPasture <= simulation.size
                        ? 'done'
                        : 'warning'
                    "
                  />
                  <strong>{{ totalPasture | format }} ha</strong>
                  &nbsp;de&nbsp;
                  <strong>área total</strong>
                  &nbsp;informada
                </v-chip>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensNatural"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.pictureNaturalLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsNatural()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>
                  <v-row v-for="(p, i) in pasturesNatural" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation['pasture_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focus(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Pastagem Cultivada
                </h3>
              </v-col>
            </v-row>

            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Buffel
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensBuffel"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.pictureBuffelLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsBuffel()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-row v-for="(p, i) in pasturesBuffel" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation[(p.type === 'middle') ? 'pasture_buffel' : 'pasture_buffel_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focusB(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Panicum
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensPanicum"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.picturePanicumLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsPanicum()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-row v-for="(p, i) in pasturesPanicum" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation['pasture_panicum_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focusP(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Brachiaria
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensBrachiaria"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.pictureBrachiariaLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsBrachiaria()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-row v-for="(p, i) in pasturesBrachiaria" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation['pasture_brachiaria_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focusBr(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Andropogon
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensAndropogon"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.pictureAndropogonLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsAndropogon()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-row v-for="(p, i) in pasturesAndropogon" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation['pasture_andropogon_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focusAn(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pa-3">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="mb-2 px-2">
                <h3 class="headline">
                  Cynodon
                </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="10" offset-sm="1" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
                <v-card class="pb-2">
                  <v-img
                    :src="doImagensCynodon"
                    height="400px"
                    class="white--text"
                  >
                    <v-container fill-height fluid>
                      <v-row align="end" style="height: 400px;">
                        <v-col>
                          <span class="headline">{{ this.pictureCynodonLabel }}</span>
                        </v-col>
                        <v-spacer />
                        <v-btn icon dark @click="pastureDetailsCynodon()">
                          <v-icon>info</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-row v-for="(p, i) in pasturesCynodon" :key="i" no-gutters>
                    <v-col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      class="pl-2  text-sm-center text-md-center pt-3 pb-3"
                    >
                      <h3>
                        {{ p.label }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="10"
                      sm="10"
                      offset-sm="1"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="6"
                      offset-xl="3"
                      class="pr-1"
                    >
                      <v-card-text>
                        <v-text-field
                          v-model.number="simulation['pasture_cynodon_' + p.type]"
                          :rules="rules.number"
                          suffix="ha"
                          single-line
                          outlined
                          :rows="1"
                          @focus="focusCy(p,$event)"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col xs="6" class="pr-1">
              <v-btn block color="error" @click.native="back">
                <v-icon left dark>
                  navigate_before
                </v-icon>
                Anterior
              </v-btn>
            </v-col>
            <v-col xs="6" class="pl-1">
              <v-btn block color="success" @click.native="next">
                Próximo
                <v-icon right dark>
                  navigate_next
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3" class="px-2">
          <v-form ref="form3" lazy-validation>
            <v-row class="pa-3">
              <v-col xs="12" class="mb-2 px-2">
                <h3 class="headline">
                  Rebanho
                </h3>
              </v-col>
              <v-col cols="12" xs="12">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(h, i) in herd" :key="i">
                    <v-expansion-panel-header>
                      <v-row no-gutters>
                        <v-col cols="12" class="mt-2" style="float: left">
                          {{ h.label }}
                        </v-col>
                        <v-col cols="12" class="mr-2">
                          <v-chip

                            label
                            small
                            style="float: right"
                            v-if="sumHerd(h.type) > 0"
                          >
                            {{ sumHerd(h.type) | format }} animais
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-card>
                        <v-card-text class="mb-0 pb-0">
                          Informe a
                          <strong>quantidade máxima (#)</strong> de cabeças no
                          <strong>ano</strong> e o
                          <strong>peso médio (em Kg)</strong> para cada tipo de
                          animal do rebanho de
                          <strong>{{ h.label }}</strong> desta propriedade:
                        </v-card-text>
                        <v-card-text class="my-0 py-0">
                          <v-container fluid>
                            <v-row v-for="(t, j) in type" :key="j">
                              <v-col
                                xs="12"
                                sm="12"
                                md="2"
                                lg="2"
                                xl="2"
                                class="pr-2 text-xs-left text-md-right pt-3 pb-3"
                              >
                                <h3
                                  class="subheading mx-0 px-0"
                                  style="text-transform: capitalize"
                                >
                                  {{ t }}:
                                </h3>
                              </v-col>
                              <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="pr-1">
                                <v-text-field
                                  v-model.number="simulation[h.type + '_' + t]"
                                  :rules="rules.number"
                                  suffix="#"
                                  single-line
                                  outlined
                                  :rows="1"
                                  @focus="$event.target.select()"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
                                <v-text-field
                                  v-model.number="
                                    simulation[h.type + '_' + t + '_weight']
                                  "
                                  :rules="rules.number"
                                  suffix="Kg"
                                  single-line
                                  outlined
                                  :rows="1"
                                  @focus="$event.target.select()"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col xs="6" class="pr-1">
              <v-btn block color="error" @click.native="back">
                <v-icon left dark>
                  navigate_before
                </v-icon>
                Anterior
              </v-btn>
            </v-col>
            <v-col xs="6" class="pl-1">
              <v-btn block color="success" @click.native="next">
                Próximo
                <v-icon right dark>
                  navigate_next
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-form ref="form4" lazy-validation>
            <v-row class="pa-3">
              <v-col cols="12" xs="12" class="mb-2 px-2">
                <h3 class="headline">
                  Outras Fontes
                </h3>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="3"
                class="px-2"
                v-for="(s, z) in sources"
                :key="z"
              >
                <v-text-field
                  :label="s.label"
                  v-model.number="simulation['source_' + s.name]"
                  :rules="rules.number"
                  suffix="Kg"
                />
              </v-col>
            </v-row>
            <v-row class="pa-3">
              <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="px-2 py-2 text-center">
                <v-chip label color="teal" text-color="white" align-center>
                  <v-icon left>
                    nature
                  </v-icon>
                  <strong>{{ totalNatural | format }} Kg/ano</strong>
                  &nbsp;de&nbsp;
                  <strong>Matéria Natural</strong>
                </v-chip>
              </v-col>

              <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="px-2 py-2 text-center ">
                <v-chip label color="blue-grey" text-color="white" align-center>
                  <v-icon left>
                    inbox
                  </v-icon>
                  <strong>{{ totalDry | format }} Kg/ano</strong>
                  &nbsp;de&nbsp;
                  <strong>Matéria Seca</strong>
                </v-chip>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col xs="6" class="pr-1">
              <v-btn block color="error" @click.native="back">
                <v-icon left dark>
                  navigate_before
                </v-icon>
                Anterior
              </v-btn>
            </v-col>
            <v-col xs="6" class="pl-1">
              <v-btn block color="warning" @click.native="report">
                Relatório
                <v-icon right dark>
                  pie_chart
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>

      <v-snackbar
        :timeout="2000"
        color="success"
        :multi-line="true"
        :vertical="false"
        v-model="snackbar"
      >
        Simulação salva com sucesso!
        <v-btn dark text @click.native="snackbar = false">
          Fechar
        </v-btn>
      </v-snackbar>

      <v-snackbar
        :timeout="10000"
        color="error"
        :multi-line="true"
        :vertical="false"
        v-model="error"
      >
        Reveja as informações do REBANHO! Deve haver pelo menos um animal
        informado. Sempre que informada a QUANTIDADE de um tipo de animal, é
        necessário informar o PESO MÉDIO.
        <v-btn dark text @click.native="snackbar = false">
          Fechar
        </v-btn>
      </v-snackbar>

      <message-wrapper ref="message" />

      <dialog-wrapper ref="dialog" />
    </v-main>
  </v-app>
</template>

<style>
.v-text-field__prefix {
  margin-right: 10px;
}
</style>

<script>
import timestamp from 'unix-timestamp'
import brazil from '@/assets/brazil.json'
import MessageWrapper from '../components/Message.vue'
import DialogWrapper from '../components/Dialog.vue'

import UtilHelper from '../helpers/util'

export default {
  components: {
    MessageWrapper,
    DialogWrapper
  },
  mixins: [UtilHelper],
  data () {
    return {
      pictureNaturalName: 'pasture_low',
      pictureNaturalType: 'low',
      pictureNaturalLabel: 'Baixa Oferta',
      pictureBuffelName: 'pasture_buffel_low',
      pictureBuffelType: 'low',
      pictureBuffelLabel: 'Baixa Oferta',
      picturePanicumName: 'pasture_panicum_low',
      picturePanicumType: 'low',
      picturePanicumLabel: 'Baixa Oferta',
      pictureBrachiariaName: 'pasture_brachiaria_low',
      pictureBrachiariaType: 'low',
      pictureBrachiariaLabel: 'Baixa Oferta',
      pictureAndropogonName: 'pasture_andropogon_low',
      pictureAndropogonType: 'low',
      pictureAndropogonLabel: 'Baixa Oferta',
      pictureCynodonName: 'pasture_cynodon_low',
      pictureCynodonType: 'low',
      pictureCynodonLabel: 'Baixa Oferta',

      date: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null
      },
      locations: [
        'Australia',
        'Barbados',
        'Chile',
        'Denmark',
        'Ecuador',
        'France'
      ],
      step: 1,
      simulation: null,
      snackbar: false,
      error: false,
      rules: {
        name: [
          (v) =>
            (v && v.trim().length > 0) || 'Insira um nome para sua simulação!',
          (v) =>
            (v && v.length <= 15) ||
            'O nome não pode ter mais de 15 caracteres!'
        ],
        farm: [
          (v) => (v && v.trim().length > 0) || 'Insira o nome da propriedade!',
          (v) =>
            (v && v.length <= 50) ||
            'A propriedade não pode ter mais de 50 caracteres!'
        ],
        size: [
          (v) => (v && !isNaN(v)) || 'Insira um número maior do que zero!'
        ],
        state: [
          (v) =>
            (v && v.trim().length > 0) ||
            'Selecione o estado em que a propriedade está localizada!'
        ],
        city: [
          (v) =>
            (v && v.trim().length > 0) ||
            'Selecione a cidade em que a propriedade está localizada!'
        ],
        number: [
          (v) => ((v || v === 0) && !isNaN(v)) || 'Insira um número inteiro!'
        ],
        percent: [
          (v) =>
            (v && !isNaN(v) && v > 0 && v < 100) || 'Insira uma porcentagem!'
        ],
        money: [(v) => (v && !isNaN(v)) || 'Insira um valor em reais!']
      },
      pasturesBuffel: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Cultivado com Baixa Oferta de Forragem (Degradado)',
          description:
            'Menos de 50% de cobertura da gramínea na área. Não mais que dois cortes na estação chuvosa.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Cultivado com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Cobertura da gramínea na área entre 50-89%. De 02 a 3 cortes na estação chuvosa.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Cultivado com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Mais de três cortes na estação chuvosa.'
        }
      ],
      pasturesBrachiaria: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Cultivado com Baixa Oferta de Forragem (Degradado)',
          description:
            'Menos de 50% de cobertura da gramínea na área. Não mais que dois cortes na estação chuvosa.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Cultivado com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. De 02 a 3 cortes na estação chuvosa'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Cultivado com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Mais de três cortes na estação chuvosa.'
        }
      ],

      pasturesPanicum: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Cultivado com Baixa Oferta de Forragem (Degradado)',
          description:
            'Menos de 50% de cobertura da gramínea na área. Menos de 03 cortes na época chuvosa.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Cultivado com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. De 03 a 04 cortes estação chuvosa.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Cultivado com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Mais de quatro cortes na estação chuvosa.'
        }
      ],

      pastures: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Natural com Baixa Oferta de Forragem (Degradado)',
          description:
            'Pasto com pouca forragem, abaixo de 500 kg de matéria seca por ha por ano. Mesmo na época chuvosa a produção de forragem é muito baixa com quase nenhuma espécie forrageira nativa no estrato herbáceo e abundância de espécies invasoras (exemplo: malva, mata pasto, bamburral). O solo dessas áreas em geral tem baixa cobertura, sendo muitas vezes totalmente descoberto.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Natural com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Tem áreas superpastejadas, mas também áreas preservadas com produção de forragem em média de duas toneladas de matéria seca por ha por ano. É possível identificar espécies forrageiras no estrato herbáceo, mas algumas invasoras podem estar presentes em quantidades maiores dos que as plantas desejáveis. Cobertura do solo entre 50-70%.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Natural com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Alta produção de forragem (entre três a quatro toneladas de matéria seca por ha por ano). Presença de espécies de reconhecido valor forrageiro no estrato herbáceo (Ex: erva de ovelha, feijão de rola, amendoim carcará). A cobertura do solo é sempre alta, em geral acima de 70%.'
        }
      ],

      pasturesAndropogon: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Cultivado com Baixa Oferta de Forragem (Degradado)',
          description:
            'Menos de 50% de cobertura da gramínea na área. Menos de dois cortes na época chuvosa.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Cultivado com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Entre 04-02 cortes na época chuvosa.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Cultivado com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Mais de 04 cortes na época chuvosa.'
        }
      ],

      pasturesNatural: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Natural com Baixa Oferta de Forragem (Degradado)',
          description:
            'Pasto com pouca forragem, abaixo de 500 kg de matéria seca por ha por ano. Mesmo na época chuvosa a produção de forragem é muito baixa com quase nenhuma espécie forrageira nativa no estrato herbáceo e abundância de espécies invasoras (exemplo: malva, mata pasto, bamburral). O solo dessas áreas em geral tem baixa cobertura, sendo muitas vezes totalmente descoberto.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Natural com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Tem áreas superpastejadas, mas também áreas preservadas com produção de forragem em média de duas toneladas de matéria seca por ha por ano. É possível identificar espécies forrageiras no estrato herbáceo, mas algumas invasoras podem estar presentes em quantidades maiores dos que as plantas desejáveis. Cobertura do solo entre 50-70%.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Natural com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Alta produção de forragem (entre três a quatro toneladas de matéria seca por ha por ano). Presença de espécies de reconhecido valor forrageiro no estrato herbáceo (Ex: erva de ovelha, feijão de rola, amendoim carcará). A cobertura do solo é sempre alta, em geral acima de 70%.'
        }
      ],
      pasturesCynodon: [
        {
          type: 'low',
          label: 'Baixa Oferta',
          title: 'Pasto Cultivado com Baixa Oferta de Forragem (Degradado)',
          description:
            'Menos de 50% de cobertura da gramínea na área. Menos de 03 cortes na época chuvosa.'
        },
        {
          type: 'middle',
          label: 'Média Oferta',
          title: 'Pasto Cultivado com Média Oferta de Forragem (Meio Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Entre 03-05 cortes na época chuvosa.'
        },
        {
          type: 'high',
          label: 'Alta Oferta',
          title:
            'Pasto Cultivado com Alta Oferta de Forragem (Não ou Pouco Degradado)',
          description:
            'Mais de 90% de cobertura da gramínea na área. Mais de 05 cortes na época chuvosa.'
        }
      ],

      precipitations: [
        { type: 'baixa', label: 'Entre 0 e 300 mm/ano' },
        { type: 'media', label: 'Entre 300 e 600 mm/ano' },
        { type: 'alta', label: 'Mais de 600 mm/ano' }
      ],
      herd: [
        { type: 'goats_meat', label: 'Caprinos de Corte' },
        { type: 'goats_milk', label: 'Caprinos de Leite' },
        { type: 'sheep_meat', label: 'Ovinos de Corte' },
        { type: 'cattl_meat', label: 'Gado de Corte' },
        { type: 'cattl_milk', label: 'Gado de Leite' }
      ],
      type: ['matrizes', 'reprodutores', 'jovens'],
      sources: [
        { label: 'Palma', name: 'palma' },
        { label: 'Silagem', name: 'silagem' },
        { label: 'Feno', name: 'feno' },
        { label: 'Capineira', name: 'capineira' },
        { label: 'Banco de Proteínas', name: 'proteina' }
      ]
    }
  },
  beforeCreate () {
    /*
    if (!this.$localStorage.get('user').authenticated) {
      this.$router.push('/')
    }
    */
  },
  beforeMount () {
    this.simulation = this.instantiateSimulationHelper()
  },
  mounted () {
    var self = this

    this.$db.simulation.get(this.$route.params.code, function (s) {
      self.simulation = s
    })
  },
  methods: {
    focus (p, event) {
      event.target.select()
      this.pictureNaturalName = 'pasture_' + p.type
      this.pictureNaturalType = p.type
      this.pictureNaturalLabel = p.label
    },
    focusB (p, event) {
      event.target.select()
      this.pictureBuffelName = (p.type === 'middle') ? 'pasture_buffel' : 'pasture_buffel_' + p.type
      this.pictureBuffelType = p.type
      this.pictureBuffelLabel = p.label
    },
    focusP (p, event) {
      event.target.select()
      this.picturePanicumName = 'pasture_panicum_' + p.type
      this.picturePanicumType = p.type
      this.picturePanicumLabel = p.label
    },
    focusBr (p, event) {
      event.target.select()
      this.pictureBrachiariaName = 'pasture_brachiaria_' + p.type
      this.pictureBrachiariaType = p.type
      this.pictureBrachiariaLabel = p.label
    },

    focusAn (p, event) {
      event.target.select()
      this.pictureAndropogonName = 'pasture_andropogon_' + p.type
      this.pictureAndropogonType = p.type
      this.pictureAndropogonLabel = p.label
    },

    focusCy (p, event) {
      event.target.select()
      this.pictureCynodonName = 'pasture_cynodon_' + p.type
      this.pictureCynodonType = p.type
      this.pictureCynodonLabel = p.label
    },

    store () {
      this.simulation.updated = timestamp.fromDate(new Date())

      this.$db.simulation
        .where('code')
        .equals(this.simulation.code)
        .modify(this.simulation)
        .then(() => {
          this.snackbar = true
        })
    },
    save () {
      if (this.$refs['form' + this.step].validate() && this.validateHerd()) {
        this.store()
      }
    },
    next () {
      if (this.$refs['form' + this.step].validate() && this.validateHerd()) {
        this.store()
        this.step++
      }
    },
    back () {
      if (this.$refs['form' + this.step].validate() && this.validateHerd()) {
        this.store()
        this.step--
      }
    },
    cancel () {
      this.$router.push({ path: '/home' })
    },
    report () {
      if (this.$refs['form' + this.step].validate()) {
        this.store()
        this.$router.push({ path: '/report/' + this.simulation.code })
      }
    },
    states () {
      return brazil.states.map(function (e) {
        return { name: e.name, uf: e.uf }
      })
    },
    number (evt) {
      if (!evt) evt = window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    pastureDetailsNatural () {
      for (var i = 0; i < this.pasturesNatural.length; i++) {
        if (this.pictureNaturalType === this.pasturesNatural[i].type) {
          this.$refs.dialog.open(
            this.pasturesNatural[i].title,
            this.pasturesNatural[i].description,
            'Fechar'
          )

          break
        }
      }
    },
    pastureDetailsBuffel () {
      for (var i = 0; i < this.pasturesBuffel.length; i++) {
        if (this.pictureBuffelType === this.pasturesBuffel[i].type) {
          this.$refs.dialog.open(
            this.pasturesBuffel[i].title,
            this.pasturesBuffel[i].description,
            'Fechar'
          )

          break
        }
      }
    },
    pastureDetailsPanicum () {
      for (var i = 0; i < this.pasturesPanicum.length; i++) {
        if (this.picturePanicumType === this.pasturesPanicum[i].type) {
          this.$refs.dialog.open(
            this.pasturesPanicum[i].title,
            this.pasturesPanicum[i].description,
            'Fechar'
          )

          break
        }
      }
    },
    pastureDetailsAndropogon () {
      for (var i = 0; i < this.pasturesAndropogon.length; i++) {
        if (this.pictureAndropogonType === this.pasturesAndropogon[i].type) {
          this.$refs.dialog.open(
            this.pasturesAndropogon[i].title,
            this.pasturesAndropogon[i].description,
            'Fechar'
          )

          break
        }
      }
    },

    pastureDetailsBrachiaria () {
      for (var i = 0; i < this.pasturesBrachiaria.length; i++) {
        if (this.pictureBrachiariaType === this.pasturesBrachiaria[i].type) {
          this.$refs.dialog.open(
            this.pasturesBrachiaria[i].title,
            this.pasturesBrachiaria[i].description,
            'Fechar'
          )

          break
        }
      }
    },

    pastureDetailsCynodon () {
      for (var i = 0; i < this.pasturesCynodon.length; i++) {
        if (this.pictureCynodonType === this.pasturesCynodon[i].type) {
          this.$refs.dialog.open(
            this.pasturesCynodon[i].title,
            this.pasturesCynodon[i].description,
            'Fechar'
          )

          break
        }
      }
    },

    sumHerd (type) {
      var self = this

      var sum = 0
      this.type.forEach(function (t) {
        sum += self.simulation[type + '_' + t]
      })

      return sum
    },
    validateHerd (v) {
      if (this.step !== 3) {
        return true
      } else {
        var total = 0
        for (var i = 0; i < this.herd.length; i++) {
          for (var j = 0; j < this.type.length; j++) {
            if (
              !this.simulation[this.herd[i].type + '_' + this.type[j]] !==
              !this.simulation[
                this.herd[i].type + '_' + this.type[j] + '_weight'
              ]
            ) {
              this.error = true

              return false
            }

            total += this.simulation[this.herd[i].type + '_' + this.type[j]]
          }
        }

        if (total === 0) {
          this.error = true

          return false
        }

        this.error = false

        return true
      }
    }
  },
  computed: {
    doImagensNatural () {
      return require('@/assets/img/' + this.pictureNaturalName + (this.$webp ? '.webp' : '.jpg'))
    },
    doImagensBuffel () {
      return require('@/assets/img/' + this.pictureBuffelName + (this.$webp ? '.webp' : '.jpg'))
    },
    doImagensPanicum () {
      return require('@/assets/img/' + this.picturePanicumName + (this.$webp ? '.webp' : '.jpg'))
    },
    doImagensBrachiaria () {
      return require('@/assets/img/' + this.pictureBrachiariaName + (this.$webp ? '.webp' : '.jpg'))
    },
    doImagensAndropogon () {
      return require('@/assets/img/' + this.pictureAndropogonName + (this.$webp ? '.webp' : '.jpg'))
    },
    doImagensCynodon () {
      return require('@/assets/img/' + this.pictureCynodonName + (this.$webp ? '.webp' : '.jpg'))
    },

    cities: function () {
      for (var i = 0; i < brazil.states.length; i++) {
        if (brazil.states[i].uf === this.simulation.state) {
          return brazil.states[i].cities
        }
      }

      return []
    },
    totalPasture () {
      return (
       this.simulation.pasture_low +
        this.simulation.pasture_middle +
        this.simulation.pasture_high +
        this.simulation.pasture_buffel_low +
        this.simulation.pasture_buffel +
        this.simulation.pasture_buffel_high +
        this.simulation.pasture_brachiaria_low +
        this.simulation.pasture_brachiaria_middle +
        this.simulation.pasture_brachiaria_high +
        this.simulation.pasture_panicum_low +
        this.simulation.pasture_panicum_middle +
        this.simulation.pasture_panicum_high +
        this.simulation.pasture_andropogon_low +
        this.simulation.pasture_andropogon_middle +
        this.simulation.pasture_andropogon_high +
        this.simulation.pasture_cynodon_low +
        this.simulation.pasture_cynodon_middle +
        this.simulation.pasture_cynodon_high

      )
    },

    totalNatural () {
      return (
        this.simulation.source_palma +
        this.simulation.source_silagem +
        this.simulation.source_feno +
        this.simulation.source_capineira +
        this.simulation.source_proteina

      )
    },
    totalDry () {
      return (
        0.1 * this.simulation.source_palma +
        0.3 * this.simulation.source_silagem +
        0.35 * this.simulation.source_capineira +
        0.9 * this.simulation.source_feno +
        0.3 * this.simulation.source_proteina

      )
    }
  },
  filters: {
    round: function (value) {
      if (!value) return ''

      return Math.ceil(value)
    },
    format: function (value) {
      return Number(value).toLocaleString('pt-BR')
    }
  }
}
</script>
