<template>
  <v-app light>
    <v-main :style="'background: #673A33 url(\'/img/background.' + ($webp ? 'webp' : 'png') + '\') repeat-x bottom;'">
      <v-container fluid>
        <v-row>
          <v-col xs="12">
            <p class="text-center my-3 hidden-md-and-up">
              <img src="@/assets/img/logo.png" style="width: 300px;">
            </p>
            <p class="text-center  my-4 hidden-sm-and-down">
              <img src="@/assets/img/logo.png">
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="my-3">
            <v-card class="px-3 py-2">
              <v-card-title primary-title>
                <div class="headline">
                  Bem-Vind@!
                </div>
                <v-spacer />
                <v-btn icon @click.stop="showDetails">
                  <v-icon color="primary">
                    info
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <p>
                  Este aplicativo permite simular o orçamento forrageiro para propriedades no semiárido brasileiro.
                </p>
                <p>
                  Você pode efetuar <strong>login</strong> para sincronizar suas simulações com a
                  <a href="https://embrapa.br" target="_blank" rel="noopener">Embrapa</a>, de forma a
                  <strong>recuperá-las em caso de perda ou troca do dispositivo</strong> (computador, celular, tablet, etc),
                  mas isto <strong>não é obrigatório</strong>.
                </p>
                <p>
                  Caso opte por se autenticar, seus dados poderão ser utilizados, sempre de forma anônima, em futuras pesquisas científicas para
                  criação de tecnologias inovadoras para a agropecuária. veja nossa "<strong>Política de Privacidade</strong>" (abaixo) para mais detalhes.
                </p>
              </v-card-text>

              <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-2 my-0">
                {{ message }}
              </v-alert>

              <v-window v-model="step">
                <v-window-item class="px-3">
                  <v-btn @click="$router.push({ path: '/home' })" large color="grey darken-2" class="white--text" block>
                    <v-icon dark left>
                      cloud_off
                    </v-icon>
                    Utilizar Offline
                  </v-btn>

                  <v-card-text class="text-center  headline">
                    ou
                  </v-card-text>

                  <v-btn @click.stop="showPrivacy" large color="purple" class="white--text" block>
                    <v-icon dark left>
                      gavel
                    </v-icon>
                    Política de Privacidade
                  </v-btn>

                  <v-row wrap class="py-6">
                    <v-col xs="12" sm="6" md="6" lg="6" xl="6" class="px-3 py-0" style="text-align: left;">
                      <v-switch label="Li e aceito os termos." v-model="agree" class="mt-3 ml-3" />
                    </v-col>
                    <v-col xs="12" sm="6" md="6" lg="6" xl="6" class="px-3 py-0" style="text-align: right;">
                      <v-btn color="success" large :disabled="!agree" @click="step++" :block="$vuetify.breakpoint.xsOnly">
                        Prosseguir
                        <v-icon class="ml-2">
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-window-item>

                <v-divider v-if="step > 0 && !error" />

                <v-window-item>
                  <v-card-title>
                    Informe seu e-Mail
                  </v-card-title>
                  <v-form ref="form" lazy-validation>
                    <v-row wrap class="px-5">
                      <v-col
                        xs="12"
                        sm="12"
                        md="8"
                        offset-md="2"
                        lg="6"
                        offset-lg="3"
                        xl="6"
                        offset-xl="3"
                        cols="12"
                        class="px-1 pb-0"
                        style="text-align: center;"
                      >
                        <v-text-field
                          v-model="email"
                          append-icon="mdi-email"
                          outlined
                          clearable
                          label="e-Mail"
                          type="text"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col xs="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3" cols="12" class="px-1 pt-0">
                        <v-radio-group v-model="reliable" :row="!$vuetify.breakpoint.xsOnly" class="py-0 my-0">
                          <template v-slot:label>
                            <div>Este dispositivo é <strong>confiável</strong>?</div>
                          </template>
                          <v-radio
                            label="Sim"
                            :value="true"
                          />
                          <v-radio
                            label="Não"
                            :value="false"
                          />
                          <template v-slot:append>
                            <v-btn text icon @click="reliableInfo()" small>
                              <v-icon>mdi-help-circle</v-icon>
                            </v-btn>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      @click="cancel()"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer />

                    <v-btn
                      color="success"
                      depressed
                      :disabled="!validate()"
                      large
                      @click="sendPin()"
                      :loading="loading"
                    >
                      Prosseguir
                      <v-icon class="ml-1">
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-window-item>

                <v-window-item>
                  <v-card-title>
                    Insira o PIN
                  </v-card-title>
                  <v-card-text>
                    Um número de 6 dígitos foi enviado para o e-mail <strong>{{ email }}</strong>. Por favor, insira-o abaixo para continuar:
                  </v-card-text>
                  <div class="input-wrapper my-5" style="width: 280px; margin: 0 auto;">
                    <pincode v-model="pin" :length="6" />
                  </div>
                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      @click="cancel()"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer />

                    <v-btn
                      color="success"
                      depressed
                      :disabled="pin.length !== 6"
                      large
                      @click="authenticate()"
                      :loading="loading"
                    >
                      Autenticar
                      <v-icon class="ml-1">
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12">
            <p class="text-center my-4 hidden-sm-and-up">
              <img src="@/assets/img/partners-white-landscape.png" style="width: 250px;" alt="Logo da Embrapa e CNA">
            </p>

            <p class="text-center my-4 hidden-xs-only">
              <img src="@/assets/img/partners-white-landscape.png" style="width: 400px;" alt="Logo da Embrapa e CNA">
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog v-model="wait" persistent max-width="300px">
      <v-card>
        <v-card-text style="text-align: center;">
          <v-progress-circular indeterminate :size="70" :width="7" color="purple lighten-2" />
        </v-card-text>
        <v-card-title class="headline" block style="text-align: center;">
          Autenticando... por favor, aguarde!
        </v-card-title>
      </v-card>
    </v-dialog>

    <text-dialog @consent="agree = true" @dissent="agree = false" ref="dPrivacy" />
    <text-dialog ref="dDetails" />

    <v-snackbar :timeout="30000" color="info" :multi-line="true" :vertical="false" v-model="ios">
      <v-btn dark @click.native="installIOS()" class="px-3" color="orange">
        <v-icon left dark>
          get_app
        </v-icon>
        Instalar
      </v-btn>
      <v-btn dark text @click.native="ios = false">
        Fechar
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="install" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="info">
          <v-toolbar-title>Instalar no Dispositivo</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click.native="install = false">
              Fechar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-img src="@/assets/img/ios.png" contain alt="Adicionando à tela inicial no iOS" class="mt-3" />
      </v-card>
    </v-dialog>

    <beta />
  </v-app>
</template>

<script>
import axios from 'axios'
import mobile from 'mobile-device-detect'
import md5 from 'crypto-js/md5'
import base64 from 'image-to-base64'
import query from 'querystring'
import Pincode from 'vue-pincode-input'

import TextDialog from '@/components/Dialog'
import Beta from '@/components/Beta'
import ErrorHelper from '@/helpers/error'

var pkg = require('../../package.json')

export default {
  mixins: [
    ErrorHelper
  ],
  components: {
    TextDialog,
    Beta,
    Pincode
  },
  data () {
    return {
      step: 0,
      privacy: '',
      details: '',
      agree: false,
      email: '',
      pin: '',
      reliable: null,
      rules: [
        v => !v || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || 'O e-mail precisa ser válido!'
      ],
      loading: false,
      wait: false,
      error: false,
      message: '',
      mobile: mobile,
      ios: false,
      install: false
    }
  },
  mounted () {
    if (this.$localStorage.get('user').authenticated) {
      this.$router.push({ path: '/home' })
    } else {
      var email = this.$localStorage.get('email')

      if (email && email.length > 0) {
        this.agree = true
        this.reliable = true
        this.email = email
        this.step = 2
      }
    }

    this.loadFiles()

    if (mobile.isIOS && window.navigator.standalone !== true && !window.matchMedia('(display-mode: standalone)').matches && this.$localStorage.iosTryInstall) {
      this.ios = true
    }
  },
  methods: {
    installIOS () {
      this.ios = false

      this.install = true

      this.$localStorage.set('iosTryInstall', false)
    },
    loadFiles () {
      var p = 'privacy-policy.html'
      var d = 'details.html'

      var self = this

      axios.get(p).then(
        function (r) {
          self.privacy = r.data
        })

      axios.get(d).then(
        function (r) {
          self.details = r.data
        })
    },
    showPrivacy () {
      this.$refs.dPrivacy.open('Política de Privacidade', this.privacy, 'Aceito', 'Não Aceito')
    },
    showDetails () {
      this.$refs.dDetails.open('Mais Informações', this.details, 'Fechar', null, pkg.version)
    },
    reliableInfo () {
      var text = '<p>Um <strong>dispositivo confiável</strong> é um equipamento de <u>uso não compartilhado</u> (p.e., <strong>seu celular ou computador pessoal</strong>). Neste caso você permanecerá autenticado neste dispositivo por <u>tempo indeternimado</u>.</p>' +
        '<p>Caso esteja em um equipamento de uso compartilhado, tal como o computador de um saguão de hotel, marque <strong>NÃO</strong> nesta opção! Com isso, ao fechar o navegador, seu usuário será automaticamente deslogado.</p>'

      this.$refs.dPrivacy.open('Dispositivo Confiável', text, 'Ok')
    },
    validate () {
      return this.$refs.form && this.$refs.form.validate() && this.reliable !== null
    },
    sendPin () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      var err = error => {
        this.loading = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.loading = true

      const api = process.env.VUE_APP_API_URL

      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Application: process.env.VUE_APP_API_NAME
      }

      axios.get(api + '/status', { timeout: 2000, headers: headers }).then(response => {
        axios.post(api + '/pin/add', query.stringify({ email: this.email }), { headers: headers }).then(response => {
          if (this.reliable) this.$localStorage.set('email', this.email)

          this.loading = false

          this.step++
        }).catch(err)
      }).catch(err)
    },
    authenticate () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      var err = error => {
        this.loading = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.loading = true

      const api = process.env.VUE_APP_API_URL

      var headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Application: process.env.VUE_APP_API_NAME
      }

      axios.get(api + '/status', { timeout: 2000, headers: headers }).then(response => {
        axios.post(api + '/login', query.stringify({ email: this.email, pin: this.pin }), { headers: headers }).then(response => {
          const token = response.data.token

          console.log('Token: ' + token)

          headers.Authorization = 'Bearer ' + token

          axios.get(api + '/user', { headers: headers }).then(response => {
            var user = {
              authenticated: true,
              id: response.data.id,
              login: response.data.login,
              name: response.data.name,
              email: response.data.mail,
              type: response.data.type,
              language: response.data.language ? response.data.language : 'pt_BR',
              timezone: response.data.timezone ? response.data.timezone : 'America/Sao_Paulo',
              picture: '',
              token: token
            }

            console.log(JSON.stringify(user))

            const picture = 'https://www.gravatar.com/avatar/' + md5(response.data.mail).toString() + '?s=200&d=404'

            console.log(picture)

            base64(picture).then(stream => {
              if (stream.length > 128) {
                user.picture = 'data:image/png;base64,' + stream
              }
            }).catch(error => {
              console.log('Impossible to set Gravatar picture! [' + error + ']')
            }).finally(() => {
              this.$localStorage.set('user', user)
              this.$localStorage.set('reliable', this.reliable)

              this.$localStorage.set('email', '')

              this.$router.push({ path: '/home' })
            })
          }).catch(err)
        }).catch(err)
      }).catch(err)
    },
    cancel () {
      this.$localStorage.set('email', '')

      this.error = false
      this.agree = false

      this.email = ''
      this.reliable = null

      this.pin = ''

      this.loading = false

      this.step = 0
    }
  }
}
</script>
<style>
.g-signin-button,
.fb-signin-button {
  display: inline-block;
  width: 100%;
  padding: 0px;
  border-radius: 3px;
  cursor: pointer;
}
.g-signin-button i,
.fb-signin-button i {
  height: 48px;
  line-height: 42px;
  vertical-align: middle;
}
.g-signin-button {
  background-color: #C62828;
  color: #fff;
}
.fb-signin-button {
  background-color: #4267b2;
  color: #fff;
}
</style>
