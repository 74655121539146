<template>
  <v-snackbar :timeout="timeout" :color="type" :multi-line="true" :vertical="false" v-model="snackbar">
    {{ message }}
    <v-btn dark text @click.native="snackbar = false">
      Fechar
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      snackbar: false,
      message: null,
      type: 'success',
      timeout: 2000
    }
  },
  methods: {
    open (message, type, timeout) {
      this.snackbar = true
      this.message = message
      this.type = type

      if (timeout) {
        this.timeout = timeout
      } else {
        this.timeout = 2000
      }
    }
  }
}
</script>
