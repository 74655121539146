export default {
  methods: {
    errorMessage (error) {
      let message = 'O servidor remoto parece estar indisponível! Por favor, tente novamente mais tarde.'

      if (error.response !== undefined && error.response.data !== undefined && error.response.data.MESSAGE !== undefined) {
        console.log(error.response.data)

        switch (error.response.data.MESSAGE) {
          case 'Invalid or empty e-mail!':
            message = 'O e-mail inserido é inválido! Por favor, verifique se está escrito corretamente.'
            break

          case 'Invalid or empty PIN!':
            message = 'O PIN inserido é inválido! Por favor, verifique se você digitou corretamente.'
            break

          case 'Expired or not registered PIN!':
            message = 'O PIN inserido está expirado ou não foi encontrado! Por favor, verifique se inseriu corretamente ou clique em CANCELAR e refaça o processo.'
            break

          case 'User does not exist or is inactive!':
            message = 'Não foi possível obter os dados armazenados em nuvem do usuário! Por favor, avise a equipe de suporte.'
            break

          case 'Impossible to create user!':
            message = 'Não foi possível armazenar os dados em nuvem! Por favor, avise a equipe de suporte.'
            break

          case 'The e-mail cannot be sent. Maybe the server has problems at moment! Please, try again more later.':
            message = 'Não foi possível enviar o e-mail com o PIN! Por favor, tente novamente mais tarde.'
            break

          /*
          case 'IMPOSSIBLE_TO_DELETE_SIMULATIONS':
            message = 'Não foi possível apagar as suas simulações! Por favor, avise a equipe de suporte.'
            break

          case 'IMPOSSIBLE_TO_DELETE_FARMS':
            message = 'Não foi possível apagar as fazendas vinculadas à sua conta! Por favor, avise a equipe de suporte.'
            break

          case 'IMPOSSIBLE_TO_DELETE_USER':
            message = 'Não foi possível apagar a sua conta de usuário! Por favor, avise a equipe de suporte.'
            break

          case 'NAME_NOT_SEND':
            message = 'Houve um erro no envio do nome!'
            break

          case 'IMPOSSIBLE_TO_CHANGE_NAME':
            message = 'Não foi possível alterar o seu nome de usuário! Por favor, avise a equipe de suporte.'
            break
          */

          default:
            message += ' [' + error.response.data.MESSAGE + ']'

            if (error.response.data.TECHNICAL !== undefined && error.response.data.TECHNICAL != '')
              message += ' [' + error.response.data.TECHNICAL + ']'
        }
      } else {
        console.log(error)
      }

      return message
    }
  }
}
